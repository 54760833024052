import { Component, Vue } from "vue-property-decorator";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import { utils } from "@/utils/utils";
import { ReviewerpeerPreviewInterface } from "@/models/reviewer-peers-page/reviewer-peers.interface";
import moment from "moment";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import EmailModalPopupComponent from "../email-modal-popup/email-modal-popup.component.vue";

@Component({
  name: "reviewer-peers-preview",
  components: {
    AppLoaderComponent,
    PreviewPopupComponent,
    EmailModalPopupComponent,
  },
})
export default class ReviewerPeersPreview extends Vue {
  public isLoading = false;
  public errorMessage = "";
  public profileData: any = [];
  public certificationData: any = [];
  public file: any = null;
  public showPreviewPopup = false;
  public tableHeader = [
    { key: "applicationTitle", label: "Application Name" },
    { key: "createdAt", label: "Submission Date" },
    { key: "progressStatus", label: "Status" },
    { key: "action", label: "Action" },
  ];
  public applicationData = [];
  public imgType = ["jpg", "png", "jpeg", "tiff"];
  public getCertificatesFilesPathUrl = "";
  public previewData: any[] = [];
  public isReport: any;
  public emailData: any = null;

  public created() {
    const id: any = this.$route.query.id;
    this.getCertificatesFilesPathUrl =
      appConfigService.getS3CertificatesFilesPathUrl();
    this.getPeerApplicationLists(id);
  }

  public getPeerApplicationLists(id: string) {
    this.isLoading = true;
    getApplicationDetails
      .getPeersPreviewApplicationList({ id: id })
      .then((response) => {
        this.applicationData = response.appplications.sort((a: any, b: any) => {
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
        this.isLoading = false;
        this.profileData = this.applicationData.find(
          (data: ReviewerpeerPreviewInterface) => {
            return data.applicationName === "PART ONE";
          }
        );
        this.certificationData = this.applicationData.filter(
          (data: ReviewerpeerPreviewInterface) => {
            data.certificateFile = JSON.parse(data.certificateFile);
            if (
              data &&
              data.progressStatus === "ACCEPTED" &&
              data.applicationName === "PART TWO"
            ) {
              return data;
            }
          }
        );
        this.applicationData.forEach((data: ReviewerpeerPreviewInterface) => {
          data.applicationTitle = `${data.applicationName.toLowerCase()} Application`;
          data.createdAt = data.createdAt
            ? moment(data.createdAt).format("MM-DD-YYYY")
            : "--";
          data.expiryDate = data.expiryDate
            ? moment(data.expiryDate).format("MM-DD-YYYY")
            : "--";
        });
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public previewForm(row: any) {
    let query;
    if (this.$route.query.isReport) {
      query = {
        id: this.$route.query.id,
        application: row.item.applicationName,
        type: this.$route.query.type,
        isReport: this.$route.query.isReport,
        fromDate: this.$route.query.fromDate,
        toDate: this.$route.query.toDate,
      };
      if (this.$route.query.ethnicity) {
        Object.assign(query, { ethnicity: this.$route.query.ethnicity });
      }
      if (this.$route.query.gender) {
        Object.assign(query, { gender: this.$route.query.gender });
      }
      if (this.$route.query.region) {
        Object.assign(query, { region: this.$route.query.region });
        Object.assign(query, { regionId: this.$route.query.regionId });
      }
      if (this.$route.query.age) {
        Object.assign(query, { age: this.$route.query.age });
        Object.assign(query, { fromAge: this.$route.query.fromAge });
        Object.assign(query, { toAge: this.$route.query.toAge });
      }
      if (this.$route.query.education) {
        Object.assign(query, { education: this.$route.query.education });
      }
    } else {
      query = {
        id: this.$route.query.id,
        application: row.item.applicationName,
      };
    }
    this.$router.push({
      path: `peers-preview/${row.item.id}`,
      query: query,
    });
  }
  public previewCertificationForm(content: any) {
    this.file = content.certificateFile;
    if (this.file) {
      const img = new Image();
      this.previewData = [
        {
          width: img.width,
          height: img.height,
          type: this.file.type,
          originalFileName: this.file.originalFileName,
          uploadedFileName: this.file.uploadedFileName,
        },
      ];
      this.showPreviewPopup = true;
    }
  }
  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewData = [];
    }
  }
  public backUrl() {
    let filter = "";
    if (this.$route.query.ethnicity) {
      filter += `&ethnicity=${this.$route.query.ethnicity}`;
    }
    if (this.$route.query.gender) {
      filter += `&gender=${this.$route.query.gender}`;
    }
    if (this.$route.query.region) {
      filter += `&region=${this.$route.query.region}&regionId=${this.$route.query.regionId}`;
    }
    if (this.$route.query.age) {
      filter += `&age=${this.$route.query.age}&fromAge=${this.$route.query.fromAge}&toAge=${this.$route.query.toAge}`;
    }
    if (this.$route.query.education) {
      filter += `&education=${this.$route.query.education}`;
    }
    return this.$route.query.isReport
      ? `/reviewer-dashboard/peers?type=${this.$route.query.type}&isReport=${this.$route.query.isReport}&fromDate=${this.$route.query.fromDate}&toDate=${this.$route.query.toDate}${filter}`
      : "/reviewer-dashboard/peers";
  }
  public onOpenModal(row: any) {
    this.emailData = row;
    this.$bvModal.show("email-modal");
  }
  public emailSentStatus(event: boolean) {
    if (event) {
      this.$bvModal.hide("email-modal");
    }
  }
}
