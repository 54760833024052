var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-main-container"},[_c('div',{staticClass:"login-container"},[_c('div',{staticClass:"background-overlay"}),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-div"},[_c('form',{key:_vm.loginType,staticClass:"login-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"resend"},[_c('div',{staticClass:"content"},[_c('h3',{staticClass:"login-title"},[_vm._v("Forgot Password")]),(!_vm.forgetPasswordEmailVerify)?_c('p',{staticClass:"greeting"},[_vm._v(" No worries! It happens to the best of us. Please enter your registered email address below, and we'll send you a link to Reset your password. ")]):_c('p',{staticClass:"forgetpassword-verify-text"},[_vm._v(" We've sent you an email with a link to reset your password. ")]),(_vm.isInvalidUser)?_c('p',{staticClass:"is-invalid"},[_vm._v(" Enter Correct Email Id ")]):_vm._e()]),(!_vm.forgetPasswordEmailVerify)?_c('div',[_c('div',{staticClass:"form-group"},[_c('label',{class:_vm.activeField === 'email' || _vm.loginData.email
                      ? 'activeEmail'
                      : '',attrs:{"for":"email"}},[_vm._v("Email ID")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.loginData.email),expression:"loginData.email"},{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],class:{
                    'is-danger': _vm.checkValidation
                      ? _vm.$validator.errors.has('email')
                      : '',
                  },attrs:{"type":"text","name":"email","autocomplete":"off"},domProps:{"value":(_vm.loginData.email)},on:{"focusin":function($event){return _vm.activeInput('email')},"focusout":function($event){return _vm.valueCheck('email')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.loginData, "email", $event.target.value)}}}),(_vm.checkValidation)?_c('span',{staticClass:"is-invalid"},[_vm._v(_vm._s(_vm.$validator.errors.first("email")))]):_vm._e()]),_c('div',{staticClass:"submit-btn"},[_c('button',{staticClass:"btn",class:_vm.isLoading ? 'disable-btn' : '',attrs:{"type":"submit"}},[_c('span',[_vm._v("Submit")]),(!_vm.isLoading)?_c('b-icon',{attrs:{"icon":"arrow-right"}}):_vm._e(),(_vm.isLoading)?_c('AppLoaderComponent',{attrs:{"type":'small'}}):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"parentBtn"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.forgetpasswordBacktoLogin()}}},[_vm._v(" Back to Login ")])])])])]),_vm._m(1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"program-content"},[_c('a',{staticClass:"image-route",attrs:{"href":"/home"}},[_c('img',{attrs:{"src":require("../../assets/images/cprs-logo.png")}})]),_c('p',{staticClass:"program-title"},[_vm._v("Certified Peer Recovery Specialist")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"login-image-container"},[_c('img',{staticClass:"login-img",attrs:{"src":require("../../assets/images/login-page.png")}}),_c('div',{staticClass:"overlay"})])
}]

export { render, staticRenderFns }