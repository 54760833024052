var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"renewal-preview-main-container",attrs:{"tabindex":"0"}},[_c('div',{staticClass:"form-content"},[_vm._l((_vm.formData),function(formContent,index){return _c('div',{key:index,staticClass:"preview-form container-form"},[(formContent && index < _vm.limitationList)?_c('div',[(
            (_vm.isReviewerPreviewForm &&
              formContent.fields.parentSlug === _vm.tabSelect) ||
            (_vm.isReviewerPreviewForm && _vm.isMobileView) ||
            !_vm.isReviewerPreviewForm
          )?_c('b-form',{staticClass:"preview-form-container"},[(
              _vm.originalData[formContent.parent_slug] &&
              formContent.parent_slug !== 'renewalTrainingInformation' &&
              formContent.parent_slug !== 'renewalSupervisorInformation'
            )?_c('div',[(!_vm.isReviewerPreviewForm && !_vm.isUserApplication)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]),(_vm.currentRoute.split('/')[1] === 'form')?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),(
                _vm.isReviewerPreviewForm && _vm.isMobileView
                  ? _vm.isMigrationForm
                    ? formContent.parent_slug !== 'renewalSignatureSubmission'
                    : true
                  : false
              )?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])])]):_vm._e(),(_vm.isReviewerPreviewForm && _vm.tabSelect === 'signature')?_c('div',{staticClass:"description"},[_c('p',{class:formContent.fields?.headingAfterDescription ? 'p-note' : '',domProps:{"innerHTML":_vm._s(formContent.fields?.headingAfterDescription)}})]):_vm._e(),(
                (_vm.isReviewerPreviewForm &&
                  _vm.isMobileView &&
                  formContent.parent_slug ===
                    'renewalApplicationInformation') ||
                (!_vm.isMobileView &&
                  _vm.isReviewerPreviewForm &&
                  (_vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING' ||
                    _vm.progressStatus === 'NEED MORE INFO'))
              )?_c('div',{staticClass:"reviewer-form-edit"},[(
                  _vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(
                  !_vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onCilckEdit(index)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(
                  _vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e(),(
                !_vm.isReviewerPreviewForm ||
                (_vm.isReviewerPreviewForm &&
                  (_vm.isMobileView
                    ? _vm.isMigrationForm
                      ? formContent.parent_slug !==
                        'renewalSignatureSubmission'
                      : true
                    : true))
              )?_vm._l((formContent.fields
                  .formInputs),function(formInputData,indexi){return _c('div',{key:indexi,class:['fields-main-container', formInputData[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
                    formInputData.length == 2
                      ? 'double-input'
                      : formInputData.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputData),function(formInput,indexj){return _c('div',{key:indexj,staticClass:"form-label",class:[
                      !_vm.originalData[formContent.parent_slug][
                        formInput.name
                      ] && _vm.isMigrationForm
                        ? 'display-none'
                        : '',
                      _vm.fieldUpdate(formInput.name) === true
                        ? 'display-none'
                        : '',
                      formInput.fieldClass,
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      _vm.classNameChooser(formInput?.class),
                    ]},[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputData[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                          ? 'label-container'
                          : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                          formInput.label &&
                          (formInput?.validate?.required === true ||
                            formInput?.fileValid?.veeValidate === 'required')
                        )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[formInput.class],attrs:{"editForm":_vm.isEditReviewerForm === true &&
                        _vm.tabSelect !== 'renewal-code-of-ethics' &&
                        _vm.tabSelect !== 'renewal-signature' &&
                        formContent.parent_slug !== 'renewalHandbook' &&
                        formContent.parent_slug !==
                          'renewalSignatureSubmission'
                          ? true
                          : false,"formType":'preview',"payloadData":_vm.previewPayload[formInput.name],"payloadDataAll":_vm.previewPayload,"signatureType":_vm.currentRoute.split('/')[1] === 'form'
                          ? {
                              payloadName: 'renewalPayLoadData',
                              payloadSignatureKey:
                                'renewalSignatureSubmission',
                              resetButton: false,
                            }
                          : {},"signature":_vm.previewPayload &&
                        _vm.previewPayload['renewalSignatureSubmission'],"signatureLabelAndValidate":{
                        label: formInput?.signatureLabel,
                        validate: formInput?.signatureValidate,
                      },"attachmentData":{
                        files:
                          _vm.previewPayload[formContent.parent_slug][
                            formInput.name
                          ],
                        payloadName: '',
                      },"isEditFile":_vm.isEditReviewerForm,"isPreviewForm":true,"keyName":formInput.name,"autocomplete":"none","fileValidation":formInput?.fileValid,"checkFileValidation":_vm.isFileValid,"isEditReviewerForm":_vm.isEditReviewerForm,"applicationName":'renewal',"previewScopeOfActivityData":{
                        payloadName:
                          _vm.currentRoute.split('/')[1] === 'form'
                            ? 'renewalPayLoadData'
                            : '',
                        data: _vm.previewPayload[formContent.parent_slug][
                          'handbook'
                        ],
                      },"scopeofActivityValidation":_vm.checkSOAValid,"textAreaMaxlength":formInput?.propBind?.maxlength},on:{"isNotUploadedKeyName":function($event){return _vm.notFileUploaded($event)},"isUploadedKeyName":function($event){return _vm.allFilesUploaded($event)},"resetFileKey":function($event){return _vm.resetFileKey($event)},"isFileValid":function($event){return _vm.fileValid($event)},"SOAValid":function($event){return _vm.scopeOfActivityValidation($event)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                          ? _vm.fieldValidation(
                              $event,
                              formInput?.propBind?.eventName,
                              formInput?.propBind.type,
                              formInput?.validate?.maxLength,
                              formInput?.name
                            )
                          : _vm.emptyEvent}},model:{value:(
                        _vm.previewPayload[formContent.parent_slug][
                          formInput.name
                        ]
                      ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug], 
                          formInput.name
                        , $$v)},expression:"\n                        previewPayload[formContent.parent_slug][\n                          formInput.name\n                        ]\n                      "}},'component',{
                        ...formInput.propBind,
                        ...formInput.validate,
                        disabled:
                          _vm.isEditReviewerForm === true &&
                          _vm.tabSelect !== 'renewal-supervisor-details' &&
                          _vm.tabSelect !== 'renewal-code-of-ethics' &&
                          _vm.tabSelect !== 'renewal-signature' &&
                          formContent.parent_slug !==
                            'renewalSupervisorInformation' &&
                          formContent.parent_slug !==
                            'renewalSignatureSubmission'
                            ? false
                            : true,
                      },false)),(
                        formInput.field === 'b-form-textarea' &&
                        formInput.propBind.maxlength &&
                        _vm.isEditReviewerForm &&
                        _vm.isReviewerPreviewForm
                      )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.previewPayload[formContent.parent_slug][ formInput.name ].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e(),(formInput.subLabel)?_c('label',{staticClass:"sub-label"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e(),(
                        _vm.checkValidation &&
                        _vm.$validator.errors.has(formInput?.validate?.name)
                      )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "zip" ? _vm.$validator.errors.first(formInput?.validate?.name) : formInput.propBind.type === "date" ? "Please enter valid date" : formInput.name === "state" ? "This field must be 2 letter" : "This field is required")+" ")]):_vm._e()],1)}),0)])}):_vm._e()],2):_vm._e(),(
              _vm.originalData[formContent.parent_slug] &&
              formContent.parent_slug == 'renewalTrainingInformation'
            )?_c('div',{key:_vm.isTrainingReferesh},[(!_vm.isReviewerPreviewForm && !_vm.isUserApplication)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title",domProps:{"innerHTML":_vm._s('Continuing Education')}})]),(!_vm.isReviewerPreviewForm)?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),(
                _vm.addTrainingForm &&
                ((_vm.isReviewerPreviewForm &&
                  _vm.isMobileView &&
                  formContent.parent_slug !== 'renewalTrainingInformation') ||
                  (!_vm.isMobileView &&
                    _vm.isReviewerPreviewForm &&
                    (_vm.progressStatus === 'RECEIVED' ||
                      _vm.progressStatus === 'PROCESSING' ||
                      _vm.progressStatus === 'NEED MORE INFO')))
              )?_c('div',{staticClass:"reviewer-form-edit"},[(
                  _vm.isEditReviewerForm &&
                  _vm.progressStatus === 'RECEIVED' &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(
                  !_vm.isEditReviewerForm &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onCilckEdit(index)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(
                  _vm.isEditReviewerForm &&
                  _vm.progressStatus === 'RECEIVED' &&
                  _vm.tabSelect !== 'renewal-supervisor-details' &&
                  _vm.tabSelect !== 'renewal-code-of-ethics' &&
                  _vm.tabSelect !== 'renewal-signature'
                )?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e(),(_vm.addTrainingForm.length)?_vm._l((_vm.addTrainingForm),function(addTrainingFormList,indexi){return _c('div',{key:indexi},[(!_vm.revomedTraningIndex.includes(indexi))?_c('div',{staticClass:"training-lists"},[_c('p',{staticClass:"training-count",domProps:{"innerHTML":_vm._s('Training' + ' ')}}),_c('div',_vm._l((addTrainingFormList),function(formInputData,indexj){return _c('div',{key:indexj,class:[
                        'fields-main-container',
                        formInputData[0].mainClass,
                      ]},[_c('div',{staticClass:"small-content form-div",class:[
                          formInputData.length == 2
                            ? 'double-input'
                            : formInputData.length > 2
                            ? 'multi-input'
                            : '',
                        ]},_vm._l((formInputData),function(formInput,indexk){return _c('div',{key:indexk,staticClass:"form-label"},[(formInput.label)?_c('div',{class:formInput.label !== null
                                ? 'label-container'
                                : 'display-label'},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(formInput.propBind?.type === "file" && _vm.previewPayload[formContent.parent_slug][ formInput.name ]?.length ? formInput?.otherLabel : formInput.label)+" ")]),(
                                formInput.label &&
                                (formInput?.validate?.required === true ||
                                  formInput?.fileValid?.veeValidate ===
                                    'required')
                              )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e(),(formInput.note)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.note))]):_vm._e()]):_vm._e(),_c('div',[_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.isEditReviewerForm,tag:"component",class:[formInput.class],attrs:{"name":formInput?.validate?.name + ' ' + indexi,"payloadData":_vm.previewPayload[formInput.name],"payloadDataAll":_vm.previewPayload,"formInput":formInput.propBind,"editForm":_vm.isEditReviewerForm,"formType":'preview',"formIndex":indexi,"autocomplete":"off","attachmentData":{
                                files:
                                  _vm.previewPayload.renewalTrainingInformation[
                                    indexi
                                  ][formInput.name],
                                payloadName: '',
                              },"isEditFile":_vm.isEditReviewerForm,"isPreviewForm":true,"keyName":formInput.name,"fileValidation":formInput?.fileValid,"checkFileValidation":_vm.isFileValid,"textAreaMaxlength":formInput?.propBind?.maxlength},on:{"isNotUploadedKeyName":function($event){return _vm.notFileUploaded($event)},"isUploadedKeyName":function($event){return _vm.allFilesUploaded($event)},"isFileValid":function($event){return _vm.fileValid($event)}},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                                  ? _vm.fieldValidation(
                                      $event,
                                      formInput?.propBind?.eventName,
                                      formInput?.propBind.type,
                                      formInput?.validate?.maxLength,
                                      formInput?.name
                                    )
                                  : _vm.emptyEvent}},model:{value:(
                                _vm.previewPayload.renewalTrainingInformation[
                                  indexi
                                ][formInput.name]
                              ),callback:function ($$v) {_vm.$set(_vm.previewPayload.renewalTrainingInformation[
                                  indexi
                                ], formInput.name, $$v)},expression:"\n                                previewPayload.renewalTrainingInformation[\n                                  indexi\n                                ][formInput.name]\n                              "}},'component',{
                                ...formInput.propBind,
                                disabled:
                                  _vm.isEditReviewerForm === true ? false : true,
                              },false)),(formInput.subLabel)?_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]):_vm._e()],1),(
                              formInput.field == 'b-form-upload-file' &&
                              _vm.isEditReviewerForm
                            )?_c('div',{staticClass:"remove-btn-container"},[_c('div',{staticClass:"remove-btn-div"},[_c('b-button',{staticClass:"btn-remove btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeTraining(indexi)}}},[_c('b-icon',{staticClass:"icon-plus",attrs:{"icon":"x-lg"}}),_vm._v(" Remove ")],1)],1)]):_vm._e(),(
                              formInput.name === 'trainingName' &&
                              _vm.checkValidation &&
                              _vm.trainingDuplicateIndexs.length &&
                              _vm.trainingDuplicateIndexs.includes(indexi)
                            )?_c('span',{staticClass:"is-invalid"},[_vm._v("Already this training is added")]):_vm._e(),(
                              _vm.checkValidation &&
                              _vm.$validator.errors.has(
                                formInput?.validate?.name + ' ' + indexi
                              )
                            )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" || formInput.name === "zip" ? _vm.$validator.errors .first( formInput?.validate?.name + " " + indexi ) .replace(indexi.toString(), "") : formInput.propBind.type === "date" ? "Please enter valid date" : "This field is required")+" ")]):_vm._e()])}),0)])}),0)]):_vm._e()])}):_vm._e(),(!_vm.addTrainingForm.length && !_vm.isEditReviewerForm)?_c('div',{staticClass:"no-data-container",class:_vm.isReviewerPreviewForm ? 'reviewer-preview-form' : ''},[(!_vm.isUserApplication)?_c('p',{staticClass:"no-data-text"},[_vm._v(" No trainings are added, if you want to add please click the \"Edit\" button ")]):_vm._e(),(_vm.isUserApplication)?_c('p',{staticClass:"no-data-text user-text"},[_vm._v(" No trainings are added ")]):_vm._e()]):_vm._e(),(_vm.isEditReviewerForm)?_c('div',{staticClass:"add-btn-div"},[_c('b-button',{staticClass:"btn-add",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addTraining()}}},[_c('b-icon',{staticClass:"icon-plus",attrs:{"icon":"plus-lg"}}),_vm._v(" "+_vm._s(_vm.previewPayload.renewalTrainingInformation.length ? "Add More Training" : "Add Training")+" ")],1)],1):_vm._e()],2):_vm._e(),(
              _vm.originalData[formContent.parent_slug] &&
              formContent.parent_slug === 'renewalSupervisorInformation'
            )?_c('div',{staticClass:"supervisor-form-container"},[(!_vm.isReviewerPreviewForm && !_vm.isUserApplication)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])]),(_vm.currentRoute.split('/')[1] === 'form')?_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title edit",on:{"click":function($event){return _vm.editForm(formContent.parent_slug)}}},[_vm._v(" Edit "),_c('b-icon',{staticClass:"icon-pen",attrs:{"icon":"pencil-fill"}})],1)]):_vm._e()]):_vm._e(),(_vm.isReviewerPreviewForm && _vm.isMobileView)?_c('div',{staticClass:"form-edit"},[_c('div',{staticClass:"description"},[_c('h1',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formContent.fields.formHeader)+" ")])])]):_vm._e(),_vm._l((_vm.supervisorFormFields),function(formInputData,idx){return _c('div',{key:idx},[_c('div',{staticClass:"supervisor-title title-loop"},[_c('p',{staticClass:"form-title"},[_vm._v(" "+_vm._s(formInputData.title + " " + (idx + 1))+" ")]),(_vm.isUserApplication)?_c('div',{staticClass:"status"}):_vm._e(),(
                    _vm.progressStatus === 'RECEIVED' ||
                    _vm.progressStatus === 'PROCESSING' ||
                    _vm.progressStatus === 'NEED MORE INFO'
                  )?_c('div',{staticClass:"edit-container"},[(_vm.editIndex !== idx || !_vm.isEditReviewerForm)?_c('b-button',{staticClass:"edit-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":function($event){return _vm.onEditReference(idx)}}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"pencil-fill"}}),_vm._v(" Edit ")],1):_vm._e(),(_vm.isEditReviewerForm && _vm.editIndex === idx)?_c('b-button',{staticClass:"next-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCancelEdit}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"x-lg"}}),_vm._v(" Cancel ")],1):_vm._e(),(_vm.isEditReviewerForm && _vm.editIndex === idx)?_c('b-button',{staticClass:"save-btn btn",attrs:{"variant":"outline-primary","type":"button"},on:{"click":_vm.onCilckSave}},[_c('b-icon',{staticClass:"check-icon",attrs:{"icon":"file-earmark-medical"}}),_vm._v(" Save ")],1):_vm._e()],1):_vm._e()]),_vm._l((formInputData.inputs),function(formInputs,indexj){return _c('div',{key:indexj,class:['fields-main-container', formInputs[0].mainClass]},[_c('div',{staticClass:"small-content form-div",class:[
                    formInputs.length == 2
                      ? 'double-input'
                      : formInputs.length > 2
                      ? 'multi-input'
                      : '',
                  ]},_vm._l((formInputs),function(formInput,indexk){return _c('div',{key:indexk,staticClass:"form-label",class:[
                      formInput.subClass,
                      formInput.field === 'b-form-textarea'
                        ? 'textarea-field'
                        : '',
                      _vm.isMigrationForm &&
                      !_vm.originalData[formContent.parent_slug][idx][
                        formInput.name
                      ]
                        ? 'display-none'
                        : '',
                      _vm.classNameChooser(formInput?.class),
                      _vm.fieldUpdate(formInput.name) === true
                        ? 'display-none'
                        : '',
                    ]},[[(!formInput.label)?_c('div',{class:'label-container'},[_c('span',{staticClass:"visiblity-hidden label"},[_vm._v(" "+_vm._s(formInputs[0].label)+" ")])]):_vm._e(),(formInput.label)?_c('div',{class:formInput.label !== null
                            ? 'label-container'
                            : 'display-label'},[(formInput.label)?_c('span',{staticClass:"label",domProps:{"innerHTML":_vm._s(formInput.label + `&nbsp;&nbsp;`)}}):_vm._e(),(
                            formInput.label &&
                            (formInput?.validate?.required === true ||
                              formInput?.fileValid?.veeValidate ===
                                'required')
                          )?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]):_vm._e(),_c(formInput.field,_vm._b({directives:[{name:"validate",rawName:"v-validate",value:(formInput?.validate?.veeValidate),expression:"formInput?.validate?.veeValidate"}],key:_vm.supervisorUIUpdate,tag:"component",class:[
                          formInput.class,
                          formInput.name === 'supervisorEmail'
                            ? _vm.previewPayload[formContent.parent_slug][idx][
                                'status'
                              ] === 'PENDING'
                              ? ''
                              : 'disable'
                            : '',
                        ],attrs:{"formIndex":idx,"name":formInput?.name,"autocomplete":"off","formInput":formInput.propBind,"textAreaMaxlength":formInput?.propBind?.maxlength,"editForm":false,"formType":'preview',"disabled":_vm.isEditReviewerForm && _vm.editIndex === idx
                            ? false
                            : true,"tabindex":[
                          formInput.name === 'supervisorEmail'
                            ? _vm.previewPayload[formContent.parent_slug][idx][
                                'status'
                              ] === 'PENDING'
                              ? ''
                              : -1
                            : '',
                        ]},nativeOn:{"keypress":function($event){_vm.eventBind[formInput?.propBind?.eventName]
                            ? _vm.fieldValidation(
                                $event,
                                formInput?.propBind?.eventName,
                                formInput?.propBind.type,
                                formInput?.validate?.maxLength,
                                formInput?.name
                              )
                            : _vm.emptyEvent}},model:{value:(
                          _vm.previewPayload[formContent.parent_slug][idx][
                            formInput.name
                          ]
                        ),callback:function ($$v) {_vm.$set(_vm.previewPayload[formContent.parent_slug][idx], 
                            formInput.name
                          , $$v)},expression:"\n                          previewPayload[formContent.parent_slug][idx][\n                            formInput.name\n                          ]\n                        "}},'component',{
                          ...formInput.propBind,
                          disabled: true,
                        },false)),_c('label',{staticClass:"label-span"},[_vm._v(_vm._s(formInput.subLabel))]),(
                          _vm.checkValidation &&
                          _vm.$validator.errors.has(formInput?.name) &&
                          _vm.editIndex === idx
                        )?_c('p',{staticClass:"is-invalid"},[_vm._v(" "+_vm._s(formInput.propBind.type === "email" ? _vm.$validator.errors.first(formInput?.name) : "This field is required")+" ")]):_vm._e(),(
                          formInput.field === 'b-form-textarea' &&
                          formInput.propBind.maxlength
                        )?_c('div',{staticClass:"word-count-div"},[_c('p',{staticClass:"word-count"},[_vm._v(" "+_vm._s(_vm.previewPayload[formContent.parent_slug][idx][ formInput.name ].length)+"/"+_vm._s(formInput.propBind.maxlength)+" ")])]):_vm._e()]],2)}),0)])})],2)})],2):_vm._e()]):_vm._e()],1):_vm._e()])}),(!_vm.isUserApplication)?_c('EditHistoryComponent',{attrs:{"previewPayload":_vm.previewPayload,"formData":_vm.formData,"tabSelect":_vm.tabSelect,"isClickedSave":_vm.isClickSavebtn,"originalData":_vm.originalData,"removeMultiForm":_vm.removeMultiForm,"editIndex":_vm.editIndex},on:{"editedData":function($event){return _vm.editedPreviewPayloadData($event)},"isConfirmChanges":function($event){return _vm.isConfirmChanges($event)},"isRevertedChanges":function($event){return _vm.isRevertedChanges($event)},"removedTrainingIdx":function($event){return _vm.removedTrainingIdx($event)}}}):_vm._e()],2),(_vm.isLoading)?_c('span',{staticClass:"loading-container"},[_c('p',[_vm._v("Loading...")])]):_vm._e(),(_vm.isLargeLoading)?_c('AppLoaderComponent',{attrs:{"type":'large'}}):_vm._e(),(_vm.errorMessage)?_c('ErrorPopupComponent',{attrs:{"componentName":'renewal-preview-error-popup',"errorMessage":_vm.errorMessage,"isErrorPopup":"true"},on:{"closdedErrorPopup":function($event){return _vm.closdedErrorPopup($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }