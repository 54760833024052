import { Component, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import {
  EmploymentSummariesForm,
  FormFieldContents,
  FormNavbar,
} from "@/models/renewal-employment-summary-form/renewal-employment-summary-form.interface";
import { employmentVolunteerFormApi } from "@/providers/apis/employment-volunteer-form";
import { utils } from "@/utils/utils";
import { RENEWAL_EMPLOYMENT_SUMMARY_FORM_QUERY } from "@/graphql/renewal-employment-summary-form-query";
import moment from "moment";
import SingleCheckboxComponent from "../single-checkbox-button/single-checkbox-button.component.vue";
import { docLibraryData } from "@/models/common-interface/common.interface";
import PreviewPopupComponent from "../preview-popup/preview-popup.component.vue";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "renewal-employment-summary-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-single-checkbox": SingleCheckboxComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    PreviewPopupComponent,
    ErrorPopupComponent,
  },
})
export default class RenewalEmploymentSummaryForm extends Vue {
  public formPagination = 0;
  public formNames = [
    "employmentApplicantInformation",
    "supervisorContactInformation",
    "employmentVolunteerQuestions",
    "employmentSignature",
  ];
  public applicationData: Array<EmploymentSummariesForm> = [];
  public formData: FormFieldContents[] = [];
  public applicationNavData: FormNavbar[] = [];
  public isScroll = false;
  public isValid = false;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    employmentApplicantInformation: {
      applicantFirstName: "",
      applicantLastName: "",
      certificationNumber: "",
      certificateExpirationDate: "",
      applicantPhone: "",
      applicantEmail: "",
    },
    employmentVolunteerQuestions: {
      isMinimumHours: "",
      ethicsStandards: "",
      receivedSupervision: "",
      completedSupervision: [],
    },
    supervisorContactInformation: {
      firstName: "",
      lastName: "",
      supervisorCredentials: "",
      title: "",
      agencyOrOrganization: "",
      position: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      phone: "",
      email: "",
    },
    employmentSignature: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public formTitle = {
    main_title: "",
    application_name: "",
  };
  public isLoading = false;
  public token: any;
  public applicationDetials: any;
  public applicationDataDetials: any;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public checkValidForm = false;
  public errorStatus = 0;
  public mainId = "app";
  public getAllDoc: docLibraryData[] = [];
  public showPreviewPopup = false;
  public previewFile: any[] = [];
  public errorMessage = "";
  public supervisorOfInformation: any[] = [];
  public supervisorData: any;

  public async created() {
    await utils.getAllDoc().then((data) => {
      this.getAllDoc = data;
    });
    this.token = this.$route.query?.token;
    let payloadStorage: any = localStorage.getItem(
      "renewalEmploymentPayLoadData"
    );
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      payloadStorage.employmentSignature.submissionDate =
        moment().format("MM-DD-YYYY");
      this.payLoadData = { ...payloadStorage };
    }
    this.$apollo
      .query({
        query: gql`query ${RENEWAL_EMPLOYMENT_SUMMARY_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.employmentVolunteerForRenewals.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          application_name: this.applicationData[0].attributes.application_name,
        };
        this.formData = this.applicationData[0].attributes.form_fields;
        this.applicationNavData = this.applicationData[0].attributes.navbar;
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
    if (this.token) {
      this.isLoading = true;
      employmentVolunteerFormApi
        .getEmploymentVolunteerForm(this.token, true)
        .then((result: any) => {
          this.isLoading = false;
          this.applicationDetials = result.data;
          this.applicationDataDetials =
            this.applicationDetials.data.renewalApplicationInformation;
          if (this.applicationDetials) {
            //Applicant Info
            this.payLoadData.employmentApplicantInformation.applicantFirstName =
              this.applicationDataDetials.firstName;
            this.payLoadData.employmentApplicantInformation.applicantLastName =
              this.applicationDataDetials.lastName;
            this.payLoadData.employmentApplicantInformation.certificationNumber =
              this.applicationDataDetials.certificationNumber;
            this.payLoadData.employmentApplicantInformation.certificateExpirationDate =
              this.applicationDataDetials.certificateExpirationDate;
            this.payLoadData.employmentApplicantInformation.applicantPhone =
              this.applicationDataDetials.phone;
            this.payLoadData.employmentApplicantInformation.applicantEmail =
              this.applicationDataDetials.email;
            //Supervisor Info
            this.supervisorOfInformation =
              this.applicationDetials.data.renewalSupervisorInformation;

            const decodeToken = JSON.parse(atob(this.token.split(".")[1]));
            const emailData = decodeToken.email;

            this.supervisorOfInformation.map((data: any) => {
              this.supervisorData = data;
              if (data.supervisorEmail == emailData) {
                this.payLoadData.supervisorContactInformation.firstName =
                  data.supervisorFirstName;
                this.payLoadData.supervisorContactInformation.lastName =
                  data.supervisorLastName;
                this.payLoadData.supervisorContactInformation.credentials =
                  data.supervisorCredentials;
                this.payLoadData.supervisorContactInformation.title =
                  data.supervisorTitle;
                this.payLoadData.supervisorContactInformation.agencyOrOrganization =
                  data.supervisorAgencyOrganization;
                this.payLoadData.supervisorContactInformation.phone =
                  data.supervisorPhone;
                this.payLoadData.supervisorContactInformation.email =
                  data.supervisorEmail;
              }
            });
          }
          localStorage.setItem(
            "renewalEmploymentPayLoadData",
            JSON.stringify(this.payLoadData)
          );
        })
        .catch((error) => {
          this.errorMessage = utils.errorMessage(error, true);
          this.isLoading = false;
        });
    }
  }

  public updated() {
    window.document.onclick = (e) => {
      if (e.target !== null) {
        const getElement: HTMLElement = e.target as HTMLElement;
        const tagId = getElement.id;
        const getDocElement: HTMLAnchorElement = document.getElementById(
          tagId
        ) as HTMLAnchorElement;
        if (getDocElement) {
          const docDetials = utils.filterDoc(this.getAllDoc, tagId);
          if (docDetials) {
            this.showPreviewPopup = true;
            this.previewFile = [
              {
                fileName: "",
                width: 0,
                height: 0,
                type: docDetials.mime,
                originalFileName: docDetials.name,
                url: docDetials.url,
              },
            ];
          }
        }
      }
    };
  }

  public hidePreviewPopup(event: boolean) {
    if (event === true) {
      this.showPreviewPopup = false;
      this.previewFile = [];
    }
  }

  public nextButton() {
    this.checkValidForm = true;
    localStorage.setItem(
      "renewalEmploymentPayLoadData",
      JSON.stringify(this.payLoadData)
    );
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "renewalEmploymentPayLoadData",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public onSubmit() {
    this.checkValidForm = true;
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.payLoadData.supervisorContactInformation.state =
          this.payLoadData.supervisorContactInformation.state.toLocaleUpperCase();
        if (this.payLoadData.employmentSignature.isSignature) {
          this.signatureInvalid = false;
        } else {
          this.signatureInvalid = true;
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
          return;
        }
        localStorage.setItem(
          "renewalEmploymentPayLoadData",
          JSON.stringify(this.payLoadData)
        );
        this.isLoading = true;
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
        let payloadStorage: any = localStorage.getItem(
          "renewalEmploymentPayLoadData"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        employmentVolunteerFormApi
          .postEmploymentApplicationForm(this.token, this.payLoadData, true)
          .then(() => {
            this.checkValidForm = false;
            const someData = {
              title: "Certified Young Adult Peer Support Specialist",
              SubTitle:
                "Employment/Volunteer Service Summary for Renewal Application",
            };
            this.$router.push({
              path: "/form/submitted",
              query: {
                subTitle: someData.SubTitle,
              },
            });
            localStorage.removeItem("renewalEmploymentSummaryFilledIndex");
            localStorage.removeItem("renewalEmploymentPayLoadData");
            this.isLoading = false;
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      } else {
        const el = document.getElementsByClassName("is-invalid")[0];
        if (el) {
          el.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
    });
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public emptyEvent() {
    return;
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
}
