import { Component, Prop, Vue } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_TRAINING_APPLICATION_QUERY } from "@/graphql/cprs-training-application-query";
import {
  CertificationPartOneForm,
  FormFieldContents,
  FormNavbar,
} from "@/models/certification-part-one-form/certification-part-one-form.interface";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { trainingFormApi } from "@/providers/apis/certification-part-one-form";
import DateTimePickerComponent from "../date-time-picker/date-time-picker.component.vue";
import CertificationPartOnePreviewFormComponent from "../certification-part-one-preview-form/certification-part-one-preview-form.component.vue";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
import MultiRadioButton from "../multi-radio-button/multi-radio-button.component.vue";
import moment from "moment";
import { loginApi } from "@/providers/apis/login";
import DropdownListComponent from "../dropdown-list/dropdown-list.component.vue";
import { appConfigService } from "@/providers/services/app/app-config";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";
import EligibilityPopupComponent from "../eligibility-qsn-valid-popup/eligibility-qsn-valid-popup.vue";
import { User } from "@/models/common-interface/common.interface";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";

@Component({
  name: "certification-part-one-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-multi-radio": MultiRadioButton,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-date-time-picker": DateTimePickerComponent,
    "b-preview-form": CertificationPartOnePreviewFormComponent,
    "b-form-dropdown": DropdownListComponent,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
    EligibilityPopupComponent,
  },
})
export default class CertificationPartOneFormComponent extends Vue {
  public formPagination = 0;
  public formName = "certificationPartOneBasicDetails";
  public applicationData: Array<CertificationPartOneForm> = [];
  public applicationNavData: FormNavbar[] = [];
  public formData: FormFieldContents[] = [];
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public applicationCode: any;
  public formApplicationId = "";
  public getCprsBasicData: any;
  public currentForm: any;
  public fileData: File[] = [];
  public fileLink: any;
  public signatureInvalid = false;
  public saveDataDraft = false;
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    avoidNumbersAndSpecialChar: this.avoidNumbersAndSpecialCharacter,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public payLoadData: any = {
    fillStatus: "DRAFT",
    id: "",
    certificationPartOneBasicDetails: {
      firstName: "",
      lastName: "",
      otherFirstName: "",
      otherLastName: "",
      preferredName: "",
      gender: "",
      socialSecurityNumber: "",
      dob: "",
      servedAsMilitay: [],
      ethnicHeritage: [],
      ethnicHeritageNotListed: "",
      trainingcity: [],
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      phone: "",
      email: "",
      workEmail: null,
    },
    certificationPartOneEducationExperience: {
      currentlyEmployed: [],
      highSchool: [],
      highSchoolNotListed: "",
      certificateFiles: [],
      firstJobDetails: "",
      secondJobDetails: "",
    },
    certificationPartOneProgrammaticEligibilityQuestions: {
      recoveryFromMentalDisorder: "",
      isRecoveryFromMentalDisorder: "",
      recoveryFromSubstanceDisorder: "",
      recoveryFromSubstanceDisorderLastYear: "",
      willingToDisclose: [],
      participateDiscussions: [],
      conservator: [],
    },
    certificationPartOneRecoveryNarrative: {
      descripePersonalRecovery: "",
      regularBasisToRecover: "",
      strengthsToRecovery: "",
      planToDeal: "",
      ledAGroup: "",
      likeAboutLed: "",
      groupLeadFeel: "",
      taughtAClass: "",
      likeAboutTaught: "",
      feelAboutTeaching: "",
      bestExperience: "",
      supportSystemDescription: "",
      BecomeACertifiedPeer: "",
      goodCandidateTowork: "",
      likeUsToKnow: "",
    },
    certificationPartOneSignature: {
      isSignature: "",
      submissionDate: "",
    },
  };
  public formTitle = {
    main_title: "",
    sub_title: "",
  };
  public isLoading = true;
  public errorMessage = "";
  public hideDiv: string[] = [
    "likeAboutLed",
    "groupLeadFeel",
    "likeAboutTaught",
    "feelAboutTeaching",
    "recoveryFromSubstanceDisorderLastYear",
    "isRecoveryFromMentalDisorder",
    "ethnicHeritageNotListed",
    "highSchoolNotListed",
  ];
  public checkField: string[] = [
    "recoveryFromMentalDisorder",
    "recoveryFromSubstanceDisorder",
    "ledAGroup",
    "taughtAClass",
    "ethnicHeritage",
    "highSchool",
  ];
  public textAreaCount = 0;
  public isEdit = false;
  public avoidSpecialCharandNumber = false;
  public checkValidForm = false;
  public formCompletedIndex: number[] = [];
  public mainId = "app";
  public currentRoute = "";
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public eligibilityPopup = false;
  public userDetails: User = {} as User;
  constructor() {
    super();
  }

  public async created() {
    this.userDetails = utils.getUserDetails();
    this.currentRoute = this.$route.path;
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
    loginApi
      .logInWithCredential(
        appConfigService.getClientId(),
        appConfigService.getClientKey(),
        "anonymous"
      )
      .then((data) => {
        this.isLoading = false;
        localStorage.setItem("anonymousToken", data.access_token);
      })
      .catch((error) => {
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
    this.isLoading = true;
    let payloadStorage: any = localStorage.getItem("partOneCertificatePayload");
    const formFilledIndex: any = localStorage.getItem("partOneFormFilledIndex");
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }

    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.payLoadData = { ...payloadStorage };
    } else {
      if (
        localStorage.getItem("access_token") &&
        this.userDetails &&
        this.userDetails.roles === null
      ) {
        const userDetailsEmail = this.userDetails.email;
        certificationFormApi
          .getCertificateDetials(userDetailsEmail)
          .then((result: any) => {
            const resultData = result.data.data;
            this.payLoadData.certificationPartOneBasicDetails.firstName =
              resultData.certificationPartOneBasicDetails.firstName;
            this.payLoadData.certificationPartOneBasicDetails.lastName =
              resultData.certificationPartOneBasicDetails.lastName;

            this.payLoadData.certificationPartOneBasicDetails.phone =
              resultData.certificationPartOneBasicDetails.phone;

            this.payLoadData.certificationPartOneBasicDetails.email =
              resultData.certificationPartOneBasicDetails.email;

            this.payLoadData.certificationPartOneBasicDetails.addressLine1 =
              resultData.certificationPartOneBasicDetails.addressLine1;

            this.payLoadData.certificationPartOneBasicDetails.addressLine2 =
              resultData.certificationPartOneBasicDetails.addressLine2;

            this.payLoadData.certificationPartOneBasicDetails.state =
              resultData.certificationPartOneBasicDetails.state;

            this.payLoadData.certificationPartOneBasicDetails.city =
              resultData.certificationPartOneBasicDetails.city;

            this.payLoadData.certificationPartOneBasicDetails.zip =
              resultData.certificationPartOneBasicDetails.zip;
          });
      }
    }

    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_TRAINING_APPLICATION_QUERY}`,
      })
      .then((result) => {
        this.isLoading = false;
        this.applicationData = result.data.cprsTrainingApplicationForms.data;
        this.formTitle = {
          main_title: this.applicationData[0].attributes.main_title,
          sub_title: this.applicationData[0].attributes.sub_title,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.training_application_navbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        for (let index = 0; index < this.checkField.length; index++) {
          this.fieldUpdate(this.checkField[index], true);
        }
      });
  }
  public mounted() {
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
  }
  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "certificationPartOneBasicDetails") {
      this.formPagination = 0;
    } else if (slug == "certificationPartOneEducationExperience") {
      this.formPagination = 1;
    } else if (slug == "certificationPartOneProgrammaticEligibilityQuestions") {
      this.formPagination = 2;
    } else if (slug == "certificationPartOneRecoveryNarrative") {
      this.formPagination = 3;
    } else if (slug == "certificationPartOneSignature") {
      this.payLoadData.certificationPartOneSignature.isSignature = "";
      localStorage.setItem(
        "partOneCertificatePayload",
        JSON.stringify(this.payLoadData)
      );
      this.formPagination = 4;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;
    this.payLoadData.certificationPartOneBasicDetails.state =
      this.payLoadData.certificationPartOneBasicDetails.state.toLocaleUpperCase();
    //signature validation
    if (this.formPagination == 3) {
      this.payLoadData.certificationPartOneSignature.submissionDate =
        moment().format("MM-DD-YYYY");
    }
    if (this.formPagination == 4) {
      this.payLoadData.certificationPartOneSignature.submissionDate =
        moment().format("MM-DD-YYYY");
      if (this.payLoadData.certificationPartOneSignature.isSignature) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
        return;
      }
    }

    this.$validator.validateAll().then(async (validate: any) => {
      if (validate && !this.notFilesUploadedKeyNames.length) {
        if (
          this.payLoadData.certificationPartOneBasicDetails.workEmail === ""
        ) {
          this.payLoadData.certificationPartOneBasicDetails.workEmail = null;
        }
        this.checkValidForm = false;
        this.isLoading = true;
        if (this.payLoadData.id === "") {
          await trainingFormApi
            .TrainingApplicationForm(
              this.payLoadData.fillStatus,
              this.payLoadData,
              this.currentRoute.split("/")[2]
            )
            .then((result: any) => {
              this.isLoading = false;
              this.formApplicationId = result.headers.location;
              localStorage.setItem(
                "partOneCertificateApplicationId",
                this.formApplicationId
              );
              this.payLoadData.id = localStorage.getItem(
                "partOneCertificateApplicationId"
              );
              localStorage.setItem(
                "partOneCertificatePayload",
                JSON.stringify(this.payLoadData)
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.isLoading = false;
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          const id = this.payLoadData.id;
          await trainingFormApi
            .updateTrainingForm(
              this.payLoadData,
              id,
              this.currentRoute.split("/")[2]
            )
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
          localStorage.setItem(
            "partOneCertificatePayload",
            JSON.stringify(this.payLoadData)
          );
          if (this.isEdit) {
            this.formPagination = 5;
          }
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        localStorage.setItem(
          "partOneFormFilledIndex",
          this.formCompletedIndex.toString()
        );
        this.currentForm = this.payLoadData[this.formName];
        localStorage.setItem(
          "partOneCertificatePayload",
          JSON.stringify(this.payLoadData)
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "certificationPartOneBasicDetails";
      } else if (page == 1) {
        this.formName = "certificationPartOneEducationExperience";
      } else if (page == 2) {
        this.formName = "certificationPartOneProgrammaticEligibilityQuestions";
      } else if (page == 3) {
        this.formName = "certificationPartOneRecoveryNarrative";
      } else if (page == 4) {
        this.formName = "certificationPartOneSignature";
      }
      this.formPagination = page;
    }
  }

  public async saveDraft() {
    this.$bvToast.show("example-toast");
    localStorage.setItem(
      "partOneCertificatePayload",
      JSON.stringify(this.payLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public previousButton() {
    this.isEdit = false;
    this.checkValidForm = false;
    this.notFilesUploadedKeyNames = [];
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "certificationPartOneBasicDetails";
      } else if (this.formPagination == 1) {
        this.formName = "certificationPartOneEducationExperience";
      } else if (this.formPagination == 2) {
        this.formName = "certificationPartOneProgrammaticEligibilityQuestions";
      } else if (this.formPagination == 3) {
        this.formName = "certificationPartOneRecoveryNarrative";
      } else if (this.formPagination == 4) {
        this.formName = "certificationPartOneSignature";
      }
    }
  }

  public onSubmit() {
    this.$validator.validate().then((validate: any) => {
      if (validate) {
        this.payLoadData.certificationPartOneBasicDetails.state =
          this.payLoadData.certificationPartOneBasicDetails.state.toLocaleUpperCase();
        this.payLoadData.certificationPartOneSignature.submissionDate =
          moment().format("MM-DD-YYYY");
        let payloadStorage: any = localStorage.getItem(
          "partOneCertificatePayload"
        );
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.payLoadData = { ...payloadStorage };
        }
        this.isLoading = true;
        this.payLoadData.fillStatus = "COMPLETED";
        if (
          this.payLoadData.certificationPartOneBasicDetails.workEmail === ""
        ) {
          this.payLoadData.certificationPartOneBasicDetails.workEmail = null;
        }
        if (localStorage.getItem("partOneCertificateApplicationId")) {
          this.payLoadData.id = localStorage.getItem(
            "partOneCertificateApplicationId"
          );
        }
        const id = this.payLoadData.id;
        trainingFormApi
          .updateTrainingForm(
            this.payLoadData,
            id,
            this.currentRoute.split("/")[2]
          )
          .then(() => {
            trainingFormApi
              .getTrainingApplicationForm(id, this.currentRoute.split("/")[2])
              .then((result: any) => {
                localStorage.removeItem("anonymousToken");
                const applicationCode = result.data.code;
                const applicationNameData = {
                  title: "Certified Peer Recovery Specialist Application",
                  SubTitle: "Certification: Part One",
                };
                this.$router.push({
                  path: "/form/submitted",
                  query: {
                    subTitle: applicationNameData.SubTitle,
                    applicationId: applicationCode,
                  },
                });
                this.isLoading = false;
              })
              .catch((error) => {
                this.errorMessage = utils.errorMessage(error);
                this.isLoading = false;
              });
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.removeItem("partOneCertificatePayload");
            localStorage.removeItem("partOneCertificateApplicationId");
            localStorage.removeItem("partOneFormFilledIndex");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public avoidNumbersAndSpecialCharacter(evt: any) {
    utils.avoidNumbersAndSpecialCharacters(evt);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public emptyEvent() {
    return;
  }

  public fieldUpdate(groupName = "", initial = false) {
    switch (groupName) {
      case "ethnicHeritage":
        if (
          !this.payLoadData.certificationPartOneBasicDetails.ethnicHeritage.includes(
            "Not Listed (Please Specify):"
          )
        ) {
          if (!this.hideDiv.includes("ethnicHeritageNotListed") && !initial) {
            this.hideDiv.push("ethnicHeritageNotListed");
            this.payLoadData.certificationPartOneBasicDetails.ethnicHeritageNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("ethnicHeritageNotListed") >= 0) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("ethnicHeritageNotListed"),
              1
            );
          }
        }
        break;
      case "highSchool":
        if (
          !this.payLoadData.certificationPartOneEducationExperience.highSchool.includes(
            "Other"
          )
        ) {
          if (!this.hideDiv.includes("highSchoolNotListed") && !initial) {
            this.hideDiv.push("highSchoolNotListed");
            this.payLoadData.certificationPartOneEducationExperience.highSchoolNotListed =
              "";
          } else {
            return;
          }
        } else {
          if (this.hideDiv.indexOf("highSchoolNotListed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("highSchoolNotListed"), 1);
          }
        }
        break;
      case "recoveryFromMentalDisorder":
        if (
          this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions
            .recoveryFromMentalDisorder === "Yes"
        ) {
          if (this.hideDiv.indexOf("isRecoveryFromMentalDisorder") >= 0) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("isRecoveryFromMentalDisorder"),
              1
            );
          }
        } else {
          // if (
          //   this.payLoadData
          //     .certificationPartOneProgrammaticEligibilityQuestions
          //     .recoveryFromMentalDisorder === "No"
          // ) {
          //   this.eligibilityPopup = true;
          // }

          if (
            !this.hideDiv.includes("isRecoveryFromMentalDisorder") &&
            !initial
          ) {
            this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions.isRecoveryFromMentalDisorder =
              [];
            this.hideDiv.push("isRecoveryFromMentalDisorder");
          } else {
            return;
          }
        }
        break;
      case "recoveryFromSubstanceDisorder":
        if (
          this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions
            .recoveryFromSubstanceDisorder === "Yes"
        ) {
          if (
            this.hideDiv.indexOf("recoveryFromSubstanceDisorderLastYear") >= 0
          ) {
            this.hideDiv.splice(
              this.hideDiv.indexOf("recoveryFromSubstanceDisorderLastYear"),
              1
            );
          }
        } else {
          // if (
          //   this.payLoadData
          //     .certificationPartOneProgrammaticEligibilityQuestions
          //     .recoveryFromSubstanceDisorder === "No"
          // ) {
          //   this.eligibilityPopup = true;
          // }
          if (
            !this.hideDiv.includes("recoveryFromSubstanceDisorderLastYear") &&
            !initial
          ) {
            this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions.recoveryFromSubstanceDisorderLastYear =
              [];
            this.hideDiv.push("recoveryFromSubstanceDisorderLastYear");
          } else {
            return;
          }
        }
        break;
      // case "recoveryFromSubstanceDisorderLastYear":
      //   if (
      //     this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions
      //       .recoveryFromSubstanceDisorderLastYear === "No"
      //   ) {
      //     this.eligibilityPopup = true;
      //   }
      //   break;
      // case "conservator":
      //   if (
      //     this.payLoadData.certificationPartOneProgrammaticEligibilityQuestions
      //       .conservator === "do have a conservator"
      //   ) {
      //     this.eligibilityPopup = true;
      //   }
      //   break;
      case "ledAGroup":
        if (
          this.payLoadData.certificationPartOneRecoveryNarrative.ledAGroup ===
          "Yes"
        ) {
          this.payLoadData.certificationPartOneRecoveryNarrative.likeAboutLed =
            initial
              ? this.payLoadData.certificationPartOneRecoveryNarrative
                  .likeAboutLed
              : "";
          this.payLoadData.certificationPartOneRecoveryNarrative.groupLeadFeel =
            "";
          if (this.hideDiv.indexOf("likeAboutLed") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("likeAboutLed"), 1);
          }
          if (!this.hideDiv.includes("groupLeadFeel") && !initial) {
            this.hideDiv.push("groupLeadFeel");
          }
        } else if (
          this.payLoadData.certificationPartOneRecoveryNarrative.ledAGroup ===
          "No"
        ) {
          this.payLoadData.certificationPartOneRecoveryNarrative.groupLeadFeel =
            initial
              ? this.payLoadData.certificationPartOneRecoveryNarrative
                  .groupLeadFeel
              : "";
          this.payLoadData.certificationPartOneRecoveryNarrative.likeAboutLed =
            "";
          if (this.hideDiv.indexOf("groupLeadFeel") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("groupLeadFeel"), 1);
          }
          if (!this.hideDiv.includes("likeAboutLed") && !initial) {
            this.hideDiv.push("likeAboutLed");
          }
        }
        break;
      case "taughtAClass":
        if (
          this.payLoadData.certificationPartOneRecoveryNarrative
            .taughtAClass === "Yes"
        ) {
          this.payLoadData.certificationPartOneRecoveryNarrative.likeAboutTaught =
            initial
              ? this.payLoadData.certificationPartOneRecoveryNarrative
                  .likeAboutTaught
              : "";
          this.payLoadData.certificationPartOneRecoveryNarrative.feelAboutTeaching =
            "";
          if (this.hideDiv.indexOf("likeAboutTaught") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("likeAboutTaught"), 1);
          }
          if (!this.hideDiv.includes("feelAboutTeaching") && !initial) {
            this.hideDiv.push("feelAboutTeaching");
          }
        } else if (
          this.payLoadData.certificationPartOneRecoveryNarrative
            .taughtAClass === "No"
        ) {
          this.payLoadData.certificationPartOneRecoveryNarrative.feelAboutTeaching =
            initial
              ? this.payLoadData.certificationPartOneRecoveryNarrative
                  .feelAboutTeaching
              : "";
          this.payLoadData.certificationPartOneRecoveryNarrative.likeAboutTaught =
            "";
          if (this.hideDiv.indexOf("feelAboutTeaching") >= 0) {
            this.hideDiv.splice(this.hideDiv.indexOf("feelAboutTeaching"), 1);
          }
          if (!this.hideDiv.includes("likeAboutTaught") && !initial) {
            this.hideDiv.push("likeAboutTaught");
          }
        }
        break;
      default:
        break;
    }
  }

  public navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.isFileValid = !this.isFileValid;
        //signature validation
        this.checkValidForm = true;
        if (this.formPagination == 4) {
          this.payLoadData.certificationPartOneSignature.submissionDate =
            moment().format("MM-DD-YYYY");
          if (this.payLoadData.certificationPartOneSignature.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.checkValidForm = true;
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
            return;
          }
        }

        this.$validator.validateAll().then((validate: any) => {
          if (validate && !this.notFilesUploadedKeyNames.length) {
            if (
              this.payLoadData.certificationPartOneBasicDetails.workEmail === ""
            ) {
              this.payLoadData.certificationPartOneBasicDetails.workEmail =
                null;
            }
            localStorage.setItem(
              "partOneCertificatePayload",
              JSON.stringify(this.payLoadData)
            );

            if (index === 5) {
              this.formPagination = index;
            } else {
              this.formName = slug;
              this.formPagination = index;
            }
          } else {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }
        });
      } else {
        this.notFilesUploadedKeyNames = [];
        if (index === 5) {
          this.formPagination = index;
        } else {
          this.formName = slug;
          this.formPagination = index;
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("partOneCertificatePayload");
    }
    this.$router.push({
      path: `/application`,
      query: {
        form: "part-one",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
  public closedEligibilityPopup(event: any) {
    this.eligibilityPopup = false;
    if (event) {
      this.goBack();
    }
  }
}
