import { Component, Vue } from "vue-property-decorator";
import { ReviewerPeersInterface } from "@/models/reviewer-peers-page/reviewer-peers.interface";
import moment from "moment";
import { filters } from "@/models/reviewer-part-one-page/reviewer-part-one.interface";
import {
  dateRange,
  reviewerDataTable,
} from "@/models/common-interface/common.interface";
import DateRangePicker from "vue2-daterange-picker";
import { getApplicationDetails } from "@/providers/apis/getApplictionDetails";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import { utils } from "@/utils/utils";
import {
  SortingKeyName,
  SortingOrderKey,
  TableSortEvent,
  FilterStateStorageNames,
} from "@/models/constants/filters.interface";
import { CPRS_TRAINING_APPLICATION_QUERY } from "@/graphql/cprs-training-application-query";
import { gql } from "graphql-tag";

@Component({
  name: "reviewer-peers-page",
  components: {
    DateRangePicker,
    AppLoaderComponent,
  },
})
export default class ReviewerPeersPage extends Vue {
  public peersData: ReviewerPeersInterface[] = [];
  public limit = 10;
  public currentPage = 1;
  public totalItem = 0;
  public page = 1;
  public filterDetails: filters = {} as filters;
  public showFilters = false;
  public dateRange: dateRange = {} as dateRange;
  public certifiedDateRange: dateRange = {} as dateRange;
  public certifiedReporDateRange: dateRange = {} as dateRange;

  public startDate: any;
  public endDate: any;
  public expiryFromDate: any;
  public expiryToDate: any;
  public offset = 0;
  public dateFilter = "";
  public firstName: any;
  public lastName: any;
  public email: any;
  public isLoading = false;
  public errorMessage = "";
  public isDataEmpty = false;
  public sort = "first_name,last_name";
  public sortBy = SortingOrderKey.ASC;
  public certifiedDateFilter = "";
  public certifieDateReportFilter = "";
  public tableFields = [
    { key: "firstName", label: "First Name", sortable: true },
    { key: "lastName", label: "Last Name", sortable: true },
    { key: "email", label: "Email Address", sortable: true },
    {
      key: "certificateNumber",
      label: "Certificate Number",
      thClass: "certifiactions",
      sortable: true,
    },
    {
      key: "certificateDate",
      label: "Certified Date",
      thClass: "certifiactions",
    },
    {
      key: "expiryDate",
      label: "Expiration Date",
      thClass: "renewal_date",
    },
    {
      key: "action",
      label: "Action",
      thClass: "application_action pointer-events",
    },
  ];
  public ageRange = [
    {
      lable: "18-30",
      name: "Age 18-30",
      fromAge: "18",
      toAge: "30",
    },
    {
      lable: "31-50",
      name: "Age 31-50",
      fromAge: "31",
      toAge: "50",
    },
    {
      lable: "51+",
      name: "Age 51+",
      fromAge: "51",
      toAge: "100",
    },
  ];
  public isReport: any;
  public count: any;
  public toogleStatus = false;
  public toogleGender = false;
  public toogleEducation = false;
  public toogleRegion = false;
  public toogleAge = false;
  public fromDate: any;
  public toDate: any;
  public type: any;
  public formData: any;
  public applicationData: any;
  public ethnicDetail: any;
  public genderDetail: any;
  public educationOptions: any;
  public regions: any;
  public filterDate: any;
  public applyEthnicityData: any;
  public applyGenderData: any;
  public applyEducationData: any;
  public applyRegionData: any;
  public applyAgeData: any;
  public filter: any;
  public toAge: any;
  public fromAge: any;

  public created() {
    this.isReport = this.$route.query.isReport;
    if (this.isReport) {
      this.count = this.$route.query.count;
      this.fromDate = this.$route.query.fromDate;
      this.toDate = this.$route.query.toDate;
      this.type = this.$route.query.type;
      this.toAge = this.$route.query.toAge;
      this.fromAge = this.$route.query.fromAge;
      this.filterDate =
        moment(this.fromDate).format("MMM DD") +
        "-" +
        moment(this.toDate).format("MMM DD");
      if (this.$route.query.ethnicity) {
        this.applyEthnicityData = {
          name: this.$route.query.ethnicity,
        };
      }
      if (this.$route.query.gender) {
        this.applyGenderData = {
          name: this.$route.query.gender,
        };
      }
      if (this.$route.query.region) {
        this.applyRegionData = {
          id: this.$route.query.regionId,
          name: this.$route.query.region,
        };
      }
      if (this.$route.query.age) {
        this.applyAgeData = {
          name: this.$route.query.age,
          fromAge: this.fromAge,
          toAge: this.toAge,
        };
      }
      if (this.$route.query.education) {
        this.applyEducationData = {
          name: this.$route.query.education,
        };
      }
      this.getPeerReports();
      this.getRegionList();
      this.$apollo
        .query({
          query: gql`query ${CPRS_TRAINING_APPLICATION_QUERY}`,
        })
        .then((result) => {
          this.applicationData = result.data.cprsTrainingApplicationForms.data;
          this.formData = this.applicationData[0].attributes.form_fields;
          const basicDetail = this.formData.find((data: any) => {
            return data.parent_slug === "certificationPartOneBasicDetails";
          });
          const educationDetail = this.formData.find((data: any) => {
            return (
              data.parent_slug === "certificationPartOneEducationExperience"
            );
          });
          basicDetail.fields.formInputs.map((formInput: any) => {
            const ethnicDetail = formInput.find((inputData: any) => {
              return inputData.name === "ethnicHeritage";
            });
            if (
              ethnicDetail &&
              ethnicDetail.propBind &&
              ethnicDetail.propBind.options
            ) {
              this.ethnicDetail = ethnicDetail.propBind.options;
            }
            const genderDetail = formInput.find((inputData: any) => {
              return inputData.name === "gender";
            });
            if (
              genderDetail &&
              genderDetail.propBind &&
              genderDetail.propBind.options
            ) {
              this.genderDetail = genderDetail.propBind.options;
            }
          });
          educationDetail.fields.formInputs.map((formInput: any) => {
            const education = formInput.find((inputData: any) => {
              return inputData.name === "highSchool";
            });
            if (education && education.propBind && education.propBind.options) {
              this.educationOptions = education.propBind.options;
            }
          });
        });
    } else {
      const peerFilter = localStorage.getItem(FilterStateStorageNames.PEERS)
        ? JSON.parse(localStorage.getItem(FilterStateStorageNames.PEERS) || "")
        : null;
      if (peerFilter) {
        this.filterDetails = { ...this.filterDetails, ...peerFilter };
        localStorage.removeItem(FilterStateStorageNames.PEERS);
        this.expiryFromDate = peerFilter.expiryFromDate;
        this.expiryToDate = peerFilter.expiryToDate;
        this.startDate = peerFilter.startDate;
        this.endDate = peerFilter.endDate;
        this.limit = peerFilter.limit || this.limit;
        this.offset = peerFilter.offset || this.offset;
        this.page = (this.offset + this.limit) / 10;
        this.dateFilter =
          peerFilter && peerFilter.expiryFromDate && peerFilter.expiryToDate
            ? peerFilter.expiryFromDate + " - " + peerFilter.expiryToDate
            : "";
        this.certifiedDateFilter =
          peerFilter &&
          peerFilter.certificateFromDate &&
          peerFilter.certificateToDate
            ? peerFilter.certificateFromDate +
              " - " +
              peerFilter.certificateToDate
            : "";
      }
      this.getPeerApplicationLists();
    }
  }

  public loadContent(page: number) {
    this.page = page;
    this.offset = (this.page - 1) * this.limit;
    if (this.isReport) {
      this.getPeerReports();
    } else {
      this.getPeerApplicationLists();
    }
  }

  public navigation(peer: any) {
    localStorage.setItem(
      FilterStateStorageNames.PEERS,
      JSON.stringify(this.filterDetails)
    );
    let query;
    if (this.isReport) {
      query = {
        id: peer.item.id,
        type: this.type,
        isReport: this.isReport,
        fromDate: this.fromDate,
        toDate: this.toDate,
        ethnicity: this.applyEthnicityData?.name,
        gender: this.applyGenderData?.name,
        region: this.applyRegionData?.name,
        regionId: this.applyRegionData?.id,
        age: this.applyAgeData?.name,
        education: this.applyEducationData?.name,
        fromAge: this.applyAgeData?.fromAge,
        toAge: this.applyAgeData?.toAge,
      };
    } else {
      query = {
        id: peer.item.id,
      };
    }
    this.$router.push({
      path: `peers-preview`,
      query: query,
    });
  }

  public searchOnEnter() {
    this.getPeerApplicationLists();
  }

  public clearSearchText() {
    this.filterDetails.firstName = "";
    this.filterDetails.lastName = "";
    this.filterDetails.email = "";
    this.startDate = "";
    this.endDate = "";
    this.expiryFromDate = "";
    this.expiryToDate = "";
    this.dateFilter = "";
    this.certifiedDateFilter = "";
    this.filterDetails.certificateNumber = "";
    this.sort = "first_name,last_name";
    this.sortBy = SortingOrderKey.ASC;
    if (this.isReport) {
      this.applyEthnicityData = "";
      this.applyGenderData = "";
      this.applyEducationData = "";
      this.applyRegionData = "";
      this.applyAgeData = "";
      this.filterDetails.ethnicity = "";
      this.filterDetails.gender = "";
      this.filterDetails.education = "";
      this.filterDetails.region = "";
      this.filterDetails.fromAge = "";
      this.filterDetails.toAge = "";
      this.certifieDateReportFilter = "";
      this.getPeerReports();
    } else {
      this.getPeerApplicationLists();
    }
  }

  public getPeerApplicationLists() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.expiryFromDate = this.expiryFromDate;
    this.filterDetails.expiryToDate = this.expiryToDate;
    this.filterDetails.certificateFromDate = this.startDate;
    this.filterDetails.certificateToDate = this.endDate;
    this.filterDetails.sortBy = this.sort;
    this.filterDetails.sort = this.sortBy;
    this.isLoading = true;
    const email = this.filterDetails.email
      ? encodeURIComponent(this.filterDetails.email)
      : null;
    this.peersData = [];
    getApplicationDetails
      .getPeersApplicationList({ ...this.filterDetails, email })
      .then((data) => {
        this.peersData = data.peers;
        this.isLoading = false;
        this.totalItem = data.totalCount;
        if (data.peers.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.peersData.forEach((element: ReviewerPeersInterface) => {
          element.certificateDate = element.certificateDate
            ? moment(element.certificateDate).format("MM-DD-YYYY")
            : "--";
          element.expiryDate = element.expiryDate
            ? moment(element.expiryDate).format("MM-DD-YYYY")
            : "--";
          element.certificateNumber = element.certificateNumber
            ? element.certificateNumber
            : "--";
        });
      })
      .catch((error) => {
        this.isDataEmpty = true;
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }

  public formTypeTableRowClass(item: reviewerDataTable, type: string) {
    return utils.formTypeTableRowClass(item, type);
  }

  public sortChanged(event: TableSortEvent) {
    if (event) {
      this.offset = 0;
      this.currentPage = 1;
    }
    if (
      event.sortBy &&
      SortingKeyName[event.sortBy as keyof typeof SortingKeyName] !== undefined
    ) {
      this.sort = SortingKeyName[event.sortBy as keyof typeof SortingKeyName];
      this.sortBy = event?.sortDesc
        ? SortingOrderKey.DESC
        : SortingOrderKey.ASC;
    }
    this.getPeerApplicationLists();
  }
  public dateChip(type: any) {
    if (this.dateRange) {
      if (type === "expiry") {
        this.expiryFromDate = moment(new Date(this.dateRange.startDate)).format(
          "MM-DD-YYYY"
        );
        this.expiryToDate = moment(new Date(this.dateRange.endDate)).format(
          "MM-DD-YYYY"
        );
        this.dateFilter = this.expiryFromDate + " - " + this.expiryToDate;
      }
    }
    if (this.certifiedDateRange) {
      if (type === "cerified") {
        this.startDate = moment(
          new Date(this.certifiedDateRange.startDate)
        ).format("MM-DD-YYYY");
        this.endDate = moment(new Date(this.certifiedDateRange.endDate)).format(
          "MM-DD-YYYY"
        );
        this.certifiedDateFilter = this.startDate + " - " + this.endDate;
      }
    }
    if (this.isReport) {
      if (type === "report") {
        this.fromDate = moment(
          new Date(this.certifiedReporDateRange.startDate)
        ).format("MM-DD-YYYY");
        this.toDate = moment(
          new Date(this.certifiedReporDateRange.endDate)
        ).format("MM-DD-YYYY");
        this.certifieDateReportFilter = this.fromDate + " - " + this.toDate;
        this.filterDate =
          moment(this.certifiedReporDateRange.startDate).format("MMM DD") +
          "-" +
          moment(this.certifiedReporDateRange.endDate).format("MMM DD");
      }
    }
    this.offset = 0;
    this.currentPage = 1;
  }
  public onClearStatusFilter(isDateFilter = false, index: any, type = "") {
    if (this.isReport) {
      if (type === "ethnic") {
        this.applyEthnicityData = "";
        this.filterDetails.ethnicity = "";
      }
      if (type === "gender") {
        this.applyGenderData = "";
        this.filterDetails.gender = "";
      }
      if (type === "education") {
        this.applyEducationData = "";
        this.filterDetails.education = "";
      }
      if (type === "region") {
        this.applyRegionData = "";
        this.filterDetails.region = "";
      }
      if (type === "age") {
        this.applyAgeData = "";
        this.filterDetails.fromAge = "";
        this.filterDetails.toAge = "";
      }
      if (type === "date") {
        this.certifieDateReportFilter = "";
        this.certifiedReporDateRange.startDate = null;
        this.certifiedReporDateRange.endDate = null;
        this.fromDate = this.$route.query.fromDate;
        this.toDate = this.$route.query.toDate;
      }
      this.offset = 0;
      this.currentPage = 1;
      this.getPeerReports();
    } else {
      if (isDateFilter) {
        this.dateFilter = "";
        this.expiryFromDate = "";
        this.expiryToDate = "";
        this.dateRange.startDate = null;
        this.dateRange.endDate = null;
      } else {
        this.certifiedDateFilter = "";
        this.startDate = "";
        this.endDate = "";
        this.certifiedDateRange.startDate = null;
        this.certifiedDateRange.endDate = null;
      }
      this.offset = 0;
      this.currentPage = 1;
      this.getPeerApplicationLists();
    }
  }
  public getPeerReports() {
    this.filterDetails.limit = this.limit;
    this.filterDetails.offset = this.offset;
    this.filterDetails.fromDate = this.fromDate;
    this.filterDetails.toDate = this.toDate;
    this.filterDetails.sortBy = this.sort;
    this.filterDetails.sort = this.sortBy;
    if (this.applyEthnicityData) {
      this.filterDetails.ethnicity = this.applyEthnicityData.name;
    }
    if (this.applyGenderData) {
      this.filterDetails.gender = this.applyGenderData.name;
    }
    if (this.applyEducationData) {
      this.filterDetails.education = this.applyEducationData.name;
    }
    if (this.applyRegionData) {
      this.filterDetails.region = this.applyRegionData.id;
    }
    if (this.applyAgeData) {
      this.filterDetails.fromAge = this.applyAgeData.fromAge;
      this.filterDetails.toAge = this.applyAgeData.toAge;
    }
    this.isLoading = true;
    getApplicationDetails
      .getReportPeersApplicationList({ ...this.filterDetails })
      .then((data) => {
        this.peersData = data.peers;
        this.isLoading = false;
        this.totalItem = data.totalCount;
        this.count = data.totalCount;
        if (data.peers.length > 0) {
          this.isDataEmpty = false;
        } else {
          this.isDataEmpty = true;
        }
        this.peersData.forEach((element: ReviewerPeersInterface) => {
          element.certificateDate = element.certificateDate
            ? moment(element.certificateDate).format("MM-DD-YYYY")
            : "--";
          element.expiryDate = element.expiryDate
            ? moment(element.expiryDate).format("MM-DD-YYYY")
            : "--";
          element.certificateNumber = element.certificateNumber
            ? element.certificateNumber
            : "--";
        });
      })
      .catch((error) => {
        this.isDataEmpty = true;
        this.errorMessage = utils.errorMessage(error);
        this.isLoading = false;
      });
  }
  public getRegionList() {
    getApplicationDetails.getRegionList().then((item) => {
      this.regions = item.data.regions;
    });
  }
  public goBackReport() {
    this.$router.push({
      path: `/reviewer-dashboard/reports`,
    });
  }
  public onSelectReportFilter(data: any, isContent: any) {
    this.toogleStatus = false;
    this.toogleRegion = false;
    this.toogleEducation = false;
    this.toogleGender = false;
    this.toogleAge = false;
    if (isContent === "ethnic") {
      this.applyEthnicityData = data;
    }
    if (isContent === "gender") {
      this.applyGenderData = data;
    }
    if (isContent === "education") {
      this.applyEducationData = data;
    }
    if (isContent === "region") {
      this.applyRegionData = data;
    }
    if (isContent === "age") {
      this.applyAgeData = data;
    }
  }
  public toggleFilter(type = "") {
    if (type === "region") {
      this.toogleStatus = false;
      this.toogleEducation = false;
      this.toogleGender = false;
      this.toogleAge = false;
      this.toogleRegion = !this.toogleRegion;
    }
    if (type === "education") {
      this.toogleStatus = false;
      this.toogleRegion = false;
      this.toogleGender = false;
      this.toogleAge = false;
      this.toogleEducation = !this.toogleEducation;
    }
    if (type === "gender") {
      this.toogleStatus = false;
      this.toogleRegion = false;
      this.toogleEducation = false;
      this.toogleAge = false;
      this.toogleGender = !this.toogleGender;
    }
    if (type === "age") {
      this.toogleStatus = false;
      this.toogleRegion = false;
      this.toogleEducation = false;
      this.toogleGender = false;
      this.toogleAge = !this.toogleAge;
    }
    if (type === "ethnicity") {
      this.toogleRegion = false;
      this.toogleEducation = false;
      this.toogleGender = false;
      this.toogleAge = false;
      this.toogleStatus = !this.toogleStatus;
    }
  }
}
