import { loginApi } from "@/providers/apis/login";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  public created() {
    const accessToken = localStorage.getItem("access_token");
    const notAcceptRoutes = [
      "/form/employment-summaries",
      "/form/letter-of-reference",
    ];
    const path = this.$route.path;
    if (!notAcceptRoutes.includes(path) && accessToken) {
      loginApi.getUser().catch(() => {
        console.log("Session expired");
      });
    }
  }
}
