import { DOC_LIBRARY_QUERY } from "@/graphql/doc-library-query";
import {
  STATUS,
  User,
  docLibraryData,
  reviewerDataTable,
} from "@/models/common-interface/common.interface";
import router from "@/router/router";
import apolloClient from "@/vue-apollo";
import gql from "graphql-tag";
export class Utils {
  static get instance() {
    return this.INSTANCE;
  }

  private ACCESS_TOKEN = "access_token";
  private USER_DETAILS = "user_details";
  public ANONYMOUS_TOKEN = "anonymousToken";
  public PROFILE_DETAIL = "profileData";
  public PART_ONE_PAYLOAD = "partOneCertificatePayload";
  public PART_TWO_PAYLOAD = "partTwoCertificationPayload";
  public RENEWAL_PAYLOAD = "renewalPayLoadData";
  public PART_ONE_INDEX = "partOneFormFilledIndex";
  public PART_TWO_INDEX = "partTwoFormFilledIndex";
  public RENEWAL_INDEX = "renewalFormFilledIndex";
  public emptyValues = [undefined, null, "", {}];

  public checkIsMobileView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth < 567;
  }

  public checkIsTabView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth < 1024;
  }

  public checkIsDesktopView() {
    if (typeof window === "undefined") {
      return true;
    }
    return window.innerWidth > 1024;
  }

  public checkIsIpadView() {
    if (typeof window === "undefined") {
      return false;
    }
    return window.innerWidth > 991 && window.innerWidth < 1200;
  }

  public addition(data: number[]) {
    return data.reduce((a, b) => {
      return a + b;
    }, 0);
  }

  public preventNumeric(event: any, input: any) {
    const propBind = input.propBind;
    if (propBind && propBind.type && propBind.type === "number") {
      ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"].includes(event.key) &&
        event.preventDefault();
    }
  }

  public onlyNumberKey(evt: any, length?: number) {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    } else {
      if (evt.target.value.length === length) {
        evt.preventDefault();
      }
    }
  }
  public onlyAlphabets(evt: any, fieldName = "") {
    let regex: RegExp;
    if (fieldName === "state" || fieldName === "currentState") {
      regex = /^[A-Z]*$/;
    } else {
      regex = /^[a-zA-Z]*$/;
    }
    const keyPressed: string = evt.key;

    if (!regex.test(keyPressed)) {
      evt.preventDefault();
    }
  }

  public telCharacterValidate(evt: any, length?: number) {
    const keysAllowed: string[] = [
      "-",
      "(",
      ")",
      " ",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;
    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    } else {
      if (evt.target.value.length === length) {
        evt.preventDefault();
      }
    }
  }

  public avoidNumbersAndSpecialCharacters(e: any) {
    const char = "~`!@#$%^&*()+=-_[]\\';,./{}|\":<>?0123456789";
    if (char.includes(e.key)) {
      e.preventDefault();
    }
  }

  public findandGetAllIndex(arrayValue: any, checkValue: any) {
    return arrayValue
      .map((data: any, idx: number) => (data === checkValue ? idx : ""))
      .filter(String);
  }

  public isEmptyObject(obj: any) {
    return Object.keys(obj).length === 0;
  }

  public getUserDetails() {
    const userDetails = localStorage.getItem("user_details");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  }
  public getProfileDetails() {
    const userDetails = localStorage.getItem("profileData");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  }

  public textAreaTagsConversion(value: string) {
    const replaceObj: any = {
      "\\n": "<br />",
      "\\r": "<br />",
    };
    value = value.replace(/\\n|\\r/gi, function (matched) {
      return replaceObj[matched];
    });
    return value;
  }

  public scoringSectionHideShow(
    applicationStatus: string,
    currentUserStatus: string,
    applicationName = "",
    userTaskStatus: string[] = []
  ) {
    const value = {} as any;
    const userDetails: User = this.getUserDetails();
    if (applicationStatus === STATUS.received) {
      value["showRubricTab"] = false;
    } else if (applicationStatus === STATUS.processing) {
      value["showCommentBox"] = false;
      value["showRubricTab"] = false;
      if (currentUserStatus !== STATUS.inProgress) {
        value["showCommentBoxWithDisable"] = false;
      }
    } else if (applicationStatus === STATUS.accepted) {
      value["showRubricTab"] = true;
      applicationName === "certification"
        ? (value["showCertification"] = true)
        : (value["showCommentBoxWithDisable"] = true);
    } else if (applicationStatus === STATUS.notAccepted) {
      if (applicationName === "certification") {
        value["showRubricTab"] = false;
      } else {
        if (
          currentUserStatus === STATUS.notAccepted &&
          !userDetails.roles.includes("REVIEW_CERTIFICATE")
        ) {
          value["showCommentBoxWithDisable"] = true;
          value["showRubricTab"] = true;
        } else {
          if (userTaskStatus.includes(STATUS.processing)) {
            value["showCommentBoxWithDisable"] = true;
            value["showRubricTab"] = true;
          } else {
            value["showRubricTab"] = false;
          }
        }
      }
    } else if (applicationStatus === STATUS.needMoreInfo) {
      if (applicationName === "certification") {
        value["showRubricTab"] = false;
      } else {
        if (
          currentUserStatus === STATUS.needMoreInfo &&
          userDetails.roles.includes("REVIEW_CERTIFICATE") &&
          !userTaskStatus.includes(STATUS.processing)
        ) {
          value["showRubricTab"] = false;
        } else {
          if (userTaskStatus.includes(STATUS.processing)) {
            value["showCommentBoxWithDisable"] = false;
            value["showRubricTab"] = false;
          } else {
            value["showCommentBox"] = true;
            value["showRubricTab"] = true;
          }
        }
      }
    }
    return value;
  }

  public formTypeTableRowClass(item: reviewerDataTable, type: string) {
    if (item && type === "row") {
      if (item.submittedMode === "migration") {
        return "migration";
      } else if (item.submittedMode === "offline") {
        return "offline";
      } else {
        return "";
      }
    }
  }

  public deleteLocalStorage() {
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.USER_DETAILS);
    localStorage.removeItem(this.ANONYMOUS_TOKEN);
    if (localStorage.getItem(this.PROFILE_DETAIL)) {
      localStorage.removeItem(this.PROFILE_DETAIL);
    }
    if (localStorage.getItem(this.PART_ONE_PAYLOAD)) {
      localStorage.removeItem(this.PART_ONE_PAYLOAD);
      localStorage.removeItem(this.PART_ONE_INDEX);
    }
    if (localStorage.getItem(this.PART_TWO_PAYLOAD)) {
      localStorage.removeItem(this.PART_TWO_PAYLOAD);
      localStorage.removeItem(this.PART_TWO_INDEX);
    }
    if (localStorage.getItem(this.RENEWAL_PAYLOAD)) {
      localStorage.removeItem(this.RENEWAL_PAYLOAD);
      localStorage.removeItem(this.RENEWAL_INDEX);
    }
  }
  public rightMoveTab(content: any, movingWidthSize: number) {
    if (content) {
      content.scrollLeft += movingWidthSize;
      if (content.scrollWidth - content.clientWidth === content.scrollLeft) {
        return {
          leftArrowDisable: false,
          rightArrowDisable: true,
        };
      } else {
        return {
          leftArrowDisable: false,
          rightArrowDisable: false,
        };
      }
    }
  }

  public leftMoveTab(content: any, movingWidthSize: number) {
    if (content) {
      content.scrollLeft -= movingWidthSize;
      if (content.scrollLeft === 0) {
        return {
          leftArrowDisable: true,
          rightArrowDisable: false,
        };
      } else {
        return {
          leftArrowDisable: false,
          rightArrowDisable: false,
        };
      }
    }
  }

  public addressBuilder(
    previewData: any,
    formName: string,
    addressType: string,
    isMultipleSupervisor = false,
    idx = 0
  ) {
    let buildedAddress = "";
    const defaultAddress = [
      "addressLine1",
      "addressLine2",
      "city",
      "state",
      "zip",
    ];
    const supervisorAddress = [
      "supervisorAddressLine1",
      "supervisorAddressLine2",
      "supervisorCity",
      "supervisorState",
      "supervisorZip",
    ];
    if (previewData && previewData[formName]) {
      if (addressType === "default") {
        defaultAddress.map((field) => {
          if (previewData[formName][field]) {
            buildedAddress = `${previewData[formName].addressLine1}\n\n${
              !utils.emptyValues.includes(previewData[formName].addressLine2)
                ? previewData[formName].addressLine2 + "\n\n"
                : ""
            }${previewData[formName].city}  |  ${
              previewData[formName].state
            }  |  ${previewData[formName].zip}`;
          }
        });
        return buildedAddress;
      } else if (addressType === "supervisor") {
        supervisorAddress.map((field) => {
          if (isMultipleSupervisor === true) {
            if (previewData[formName][idx][field]) {
              buildedAddress = `${
                previewData[formName][idx].supervisorAddressLine1
              }\n\n${
                !utils.emptyValues.includes(
                  previewData[formName][idx].supervisorAddressLine2
                )
                  ? previewData[formName][idx].supervisorAddressLine2 + "\n\n"
                  : ""
              }${previewData[formName][idx].supervisorCity}  |  ${
                previewData[formName][idx].supervisorState
              }  |  ${previewData[formName][idx].supervisorZip}`;
            }
          } else {
            if (previewData[formName][field]) {
              buildedAddress = `${
                previewData[formName].supervisorAddressLine1
              }\n\n${
                !utils.emptyValues.includes(
                  previewData[formName].supervisorAddressLine2
                )
                  ? previewData[formName].supervisorAddressLine2 + "\n\n"
                  : ""
              }${previewData[formName].supervisorCity}  |  ${
                previewData[formName].supervisorState
              }  |  ${previewData[formName].supervisorZip}`;
            }
          }
        });
        return buildedAddress;
      }
    }
  }

  public async getAllDoc() {
    let value: docLibraryData[] = [];
    await apolloClient
      .query({
        query: gql`query ${DOC_LIBRARY_QUERY}`,
      })
      .then((result) => {
        value = result.data.docsLibraries.data;
      });
    return value;
  }

  public filterDoc(data: docLibraryData[], keyName: string) {
    let value: docLibraryData = {} as docLibraryData;
    data.find((doc: docLibraryData) => {
      if (doc.attributes.keyName === keyName) {
        value = doc;
      }
    });
    return value?.attributes?.document.data.attributes;
  }

  public errorMessage(error: any, isNavigatetoHome = false): string {
    let message = "";
    switch (error.response.status) {
      case 401:
        message = "Session was expired";
        router.push("/");
        break;
      case 404:
        message = error.response.data.message;
        break;
      case 400:
        message = error.response.data.message;
        break;
      case 409:
        message = error.response.data.message;
        if (isNavigatetoHome) {
          setTimeout(() => {
            router.push("/home");
          }, 3000);
        }
        break;
      case 502:
        message = "Please try again after some time";
        break;
      default:
        message = error.response.data.message;
        break;
    }
    return message;
  }

  public duplicateValueIdx(
    formValues: any,
    formName = "",
    isEmail = false,
    fieldName: string
  ) {
    const duplicateIndexs = [];
    const data = formName ? formValues[formName] : formValues;
    const valueSet = new Set();
    for (let i = 0; i < data.length; i++) {
      const value = isEmail
        ? data[i][fieldName].toLowerCase()
        : data[i][fieldName];
      if (value) {
        if (valueSet.has(value)) {
          duplicateIndexs.push(i);
        } else {
          valueSet.add(value);
        }
      }
    }
    return duplicateIndexs;
  }

  public statusCaseChange(status: string) {
    const statusWordConvert =
      status === STATUS.notAccepted
        ? "not-accepted"
        : status === STATUS.needMoreInfo
        ? "more-info"
        : status.toLowerCase();

    return statusWordConvert;
  }

  public joinArrObj(data: any[], joinKey: string) {
    let joinName = "";
    joinName = data.map((element) => element[joinKey]).join(", ");
    return joinName;
  }

  public classNameChooser(className: string) {
    let classContainerName = "";
    switch (className) {
      case "common-input":
        classContainerName = "common-input-container";
        break;
      case "input-high":
        classContainerName = "input-high-container";
        break;
      default:
        break;
    }
    return classContainerName;
  }

  public UIUpdateUsingKey(value: boolean) {
    if (value === true) {
      return false;
    } else {
      return true;
    }
  }

  public disableField(isEdit: boolean, formType: string) {
    if (!isEdit && formType === "preview") {
      // Applicant Preview
      return true;
    } else {
      return false;
    }
  }

  public disableCommentandScoringSection(
    currentUserStatus: string,
    progressStatus: string,
    section: string
  ) {
    if (
      progressStatus.includes(STATUS.accepted) ||
      progressStatus.includes(STATUS.notAccepted)
    ) {
      return true;
    }
    if (progressStatus.includes(STATUS.processing)) {
      if (
        currentUserStatus.includes(STATUS.inProgress) &&
        section === "comment"
      ) {
        return false;
      } else {
        return false;
      }
    }
    if (progressStatus.includes(STATUS.needMoreInfo)) {
      if (
        currentUserStatus.includes(STATUS.inProgress) &&
        section === "comment"
      ) {
        return false;
      } else if (
        currentUserStatus.includes(STATUS.needMoreInfo) &&
        (section === "scoring" || section === "comment")
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (progressStatus.includes(STATUS.waitingForTraining)) {
      return false;
    }
    if (progressStatus.includes(STATUS.completedTraining)) {
      return false;
    }
  }

  public renewalValueSetter(
    renewalData: any,
    renewalFormName: string,
    renewalFieldName: string,
    fetchData: any,
    fetchDataField: string,
    fetchDataFormName = ""
  ) {
    return renewalData[renewalFormName][renewalFieldName]
      ? renewalData[renewalFormName][renewalFieldName]
      : fetchDataFormName
      ? fetchData[fetchDataFormName][fetchDataField]
      : fetchData[fetchDataField];
  }

  private static INSTANCE = new Utils();
}

export const utils = Utils.instance;
