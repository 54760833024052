import { Component, Vue, Watch } from "vue-property-decorator";
import { gql } from "graphql-tag";
import { CPRS_RENEWAL_APPLICATION_FORM_QUERY } from "@/graphql/cprs-renewal-application-query";
import RadioButtonComponent from "../radio-button/radio-button.component.vue";
import CheckboxButtonComponent from "../checkbox-button/checkbox-button.component.vue";
import FileUploadComponent from "../file-upload/file-upload.component.vue";
import InputOtherComponent from "../input-other/input-other.component.vue";
import SignatureSubmissionComponent from "../signature-submission/signature-submission.component.vue";
import { renewalFormApi } from "@/providers/apis/cprs-renewal-application";
import { utils } from "@/utils/utils";
import AppLoaderComponent from "../app-loader/app-loader.component.vue";
import moment from "moment";
import {
  FormFieldContents,
  FormNavbar,
  RenewalApplicationsForm,
  formtInputData,
  trainingForm,
} from "@/models/cprs-renewal-application/cprs-renewal-application.interface";
import ScopeOfActivity from "../scope-of-activity/scope-of-activity-component.vue";
import RenewalPreviewFormComponent from "../renewal-preview-form/renewal-preview-form.component.vue";
import { certificationFormApi } from "@/providers/apis/certification-part-two-form";
import ErrorPopupComponent from "../error-popup/error-popup.component.vue";
import { RENEWAL_REMAINDER } from "@/graphql/renewal_remainder-query";
import { RenewalRemainders } from "@/models/renewal-remainder/renewal-remainder.interface";
import FormInputDatepickerComponent from "../form-input-datepicker/form-input-datepicker.component.vue";

@Component({
  name: "renewal-form",
  components: {
    "b-form-radio-group": RadioButtonComponent,
    "b-form-checkbox": CheckboxButtonComponent,
    "b-form-upload-file": FileUploadComponent,
    "b-form-checkbox-input": InputOtherComponent,
    "b-form-signature-submission": SignatureSubmissionComponent,
    "b-form-renewal-preview-form": RenewalPreviewFormComponent,
    "code-of-ethics-and-scope-of-activity": ScopeOfActivity,
    "b-form-input-datepicker": FormInputDatepickerComponent,
    AppLoaderComponent,
    ErrorPopupComponent,
  },
})
export default class CprsRenewalApplicationComponent extends Vue {
  public applicationData: Array<RenewalApplicationsForm> = [];
  public formData: Array<FormFieldContents> = [];
  public applicationNavData: Array<FormNavbar> = [];
  public formPagination = 0;
  public isNavLoad = false;
  public isScroll = false;
  public isValid = false;
  public addTrainingForm: trainingForm[] = [];
  public renewalLocationId: any;
  public currentForm: any;
  public fileData: any;
  public saveDataDraft = false;
  public formName = "renewalApplicationInformation";
  public formTitle = {
    programTitle: "",
    formTitle: "",
  };
  public renewalPayLoadData: any = {
    id: "",
    fillStatus: "DRAFT",
    renewalApplicationInformation: {
      firstName: "",
      lastName: "",
      certificationNumber: "",
      certificateExpirationDate: "",
      addressLine1: "",
      addressLine2: "",
      state: "",
      city: "",
      zip: "",
      phone: "",
      email: "",
      agencyOrOrganization: "",
      position: "",
      status: [],
    },
    renewalTrainingInformation: [],
    renewalSupervisorInformation: [
      {
        supervisorFirstName: "",
        supervisorLastName: "",
        supervisorAgencyOrganization: "",
        supervisorPhone: "",
        supervisorEmail: "",
      },
    ],
    renewalHandbook: {
      handbook: {
        codeOfEthics: {
          isCodeofEthicsChecked: [],
          codeofEthicsFirstName: "",
          codeofEthicsLastName: "",
        },
        scopeOfActivity: {
          isScopeofActivityChecked: [],
          scopeofActivityFirstName: "",
          scopeofActivityLastName: "",
        },
      },
    },
    renewalSignatureSubmission: {
      isSignature: "",
      submissionDate: moment().format("MM-DD-YYYY"),
    },
  };
  public isTrainingReferesh = "false";
  public isTrainingSaved = true;
  public notSavedTrainingIndex: number[] = [];
  public userDetails: any;
  public isReviewerUser: any;
  public applicantFullDetials: any;
  public formCompletedIndex: number[] = [];
  public signatureInvalid = false;
  public checkValidForm = false;
  public mainId = "app";
  public eventBind: any = {
    acceptNumberOnly: this.numberValidation,
    telCharacter: this.telCharacterValidate,
    onlyAlphabets: this.onlyAlphabets,
  };
  public isFileValid = false;
  public notFilesUploadedKeyNames: string[] = [];
  public isLoading = true;
  public isEdit = false;
  public currentRoute = "";
  public checkSOAValid = false;
  public validSOA = true;
  public trainingDuplicateIndexs: number[] = [];
  public isNavigatefromUserDashboard = false;
  public disableFiled = ["certificationNumber", "certificateExpirationDate"];
  public errorMessage = "";
  public supervisorFormFields: formtInputData[] = [];
  public tempSupervisorFormFields: formtInputData = {} as formtInputData;
  public supervisorUIUpdate = false;
  public supervisorEmailDuplicateIndexs: number[] = [];
  public renewalRemainder: RenewalRemainders = {} as RenewalRemainders;

  public async created() {
    await this.$apollo
      .query({
        query: gql`query ${RENEWAL_REMAINDER}`,
      })
      .then((result) => {
        const remainders: any[] = result.data.renewalReminders.data;
        if (remainders.length) {
          const remaindersData = remainders.find(
            (data) => data.attributes.reminder_seq === 1
          );
          this.renewalRemainder = remaindersData.attributes;
        }
      });

    this.currentRoute = this.$route.fullPath;
    this.userDetails = utils.getUserDetails();
    const token = localStorage.getItem("access_token");

    if (this.userDetails) {
      const userRolesData = this.userDetails.roles;
      this.isReviewerUser =
        (userRolesData && userRolesData.indexOf("REVIEWER") !== -1) ||
        userRolesData !== null;
    } else {
      this.isReviewerUser = false;
    }
    let payloadStorage: any = localStorage.getItem("renewalPayLoadData");
    if (payloadStorage) {
      payloadStorage = JSON.parse(payloadStorage);
      this.renewalPayLoadData = { ...payloadStorage };
    }
    if (!this.$route.query.isDraft) {
      if (this.$route.query.id) {
        this.isNavigatefromUserDashboard = true;
        const applicantId: string = this.$route.query.id as string;
        if (!this.isReviewerUser && token && this.userDetails.roles === null) {
          await certificationFormApi
            .getCertificationApplicationForm(applicantId)
            .then((result: any) => {
              this.storeBasicDetais(result.data);
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          if (!payloadStorage) {
            this.$router.push({
              name: "login-page",
              query: { application: "renewal-form" },
            });
          }
        }
      } else {
        if (!this.isReviewerUser && token && this.userDetails.roles === null) {
          const userDetailsEmail = this.userDetails.email;
          await renewalFormApi
            .getApplicantDetials(userDetailsEmail)
            .then((result: any) => {
              this.applicantFullDetials = result.data;
              this.storeBasicDetais(this.applicantFullDetials, true);
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          if (!payloadStorage) {
            this.$router.push({
              name: "login-page",
              query: { application: "renewal-form" },
            });
          }
        }
      }
    }
    const formFilledIndex: any = localStorage.getItem("renewalFormFilledIndex");
    if (formFilledIndex) {
      formFilledIndex.split(",").forEach((data: string) => {
        this.formCompletedIndex.push(Number(data));
      });
    } else {
      if (!this.formCompletedIndex.includes(this.formPagination)) {
        this.formCompletedIndex.push(this.formPagination);
      }
    }
    if (this.formPagination === 0) {
      this.isNavLoad = true;
    }
    this.$apollo
      .query({
        query: gql`query ${CPRS_RENEWAL_APPLICATION_FORM_QUERY}`,
      })
      .then((result) => {
        this.applicationData = result.data.cprsRenewalApplications.data;
        this.formTitle = {
          programTitle: this.applicationData[0].attributes.programTitle,
          formTitle: this.applicationData[0].attributes.formTitle,
        };
        this.applicationNavData =
          this.applicationData[0].attributes.RenewalNavbar;
        this.formData = this.applicationData[0].attributes.form_fields;
        this.tempSupervisorFormFields = JSON.parse(
          JSON.stringify(
            this.applicationData[0].attributes.form_fields[2].fields
              .formInputs[0]
          )
        );
        if (this.renewalPayLoadData.renewalSupervisorInformation.length) {
          const addSupervisorForm =
            this.renewalPayLoadData.renewalSupervisorInformation.length;
          for (let index = 0; index < addSupervisorForm; index++) {
            this.supervisorFormFields.push(this.tempSupervisorFormFields);
          }
        }
        this.isLoading = false;
      });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("remove-container");
    }
  }
  public mounted() {
    const mainEl: any = document.querySelector(".main-content");
    if (mainEl) {
      mainEl.addEventListener("keyup", (event: any) => {
        if (event.shiftKey && event.keyCode == 9) {
          const el = event.path[0];
          if (el) {
            el.scrollIntoView({ block: "center", behavior: "smooth" });
          }
        }
      });
    }
  }
  public storeBasicDetais(basicdetails: any, isTrainingData = false) {
    this.renewalPayLoadData.renewalApplicationInformation.certificationNumber =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "certificationNumber",
        basicdetails,
        "certificateNumber"
      );

    this.renewalPayLoadData.renewalApplicationInformation.certificateExpirationDate =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "certificateExpirationDate",
        basicdetails,
        "expiryDate"
      );

    this.renewalPayLoadData.renewalApplicationInformation.firstName =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "firstName",
        basicdetails,
        "firstName"
      );

    this.renewalPayLoadData.renewalApplicationInformation.lastName =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "lastName",
        basicdetails,
        "lastName"
      );

    this.renewalPayLoadData.renewalApplicationInformation.phone =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "phone",
        basicdetails.data,
        "phone",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.email =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "email",
        basicdetails.data,
        "email",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.addressLine1 =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "addressLine1",
        basicdetails.data,
        "addressLine1",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.addressLine2 =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "addressLine2",
        basicdetails.data,
        "addressLine2",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.state =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "state",
        basicdetails.data,
        "state",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.city =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "city",
        basicdetails.data,
        "city",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );

    this.renewalPayLoadData.renewalApplicationInformation.zip =
      utils.renewalValueSetter(
        this.renewalPayLoadData,
        "renewalApplicationInformation",
        "zip",
        basicdetails.data,
        "zip",
        isTrainingData
          ? "certificationPartOneBasicDetails"
          : "certificationPartTwoApplicationForm"
      );
  }

  public fileSave(fileData: any, index: number) {
    this.isFileValid = !this.isFileValid;
    this.$validator.validateAll().then(async (validate) => {
      if (validate && !this.notFilesUploadedKeyNames.length) {
        this.isTrainingReferesh = this.changeStateForTraining(
          this.isTrainingReferesh
        );
        const findIndex = this.notSavedTrainingIndex.indexOf(index);
        this.notSavedTrainingIndex.splice(findIndex, 1);
        localStorage.setItem(
          "renewalPayLoadData",
          JSON.stringify(this.renewalPayLoadData)
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public scopeOfActivityValidation(event: boolean) {
    this.validSOA = event;
  }

  public nextButton() {
    this.checkValidForm = true;
    this.isFileValid = !this.isFileValid;
    this.checkSOAValid = !this.checkSOAValid;
    this.renewalPayLoadData.renewalApplicationInformation.state =
      this.renewalPayLoadData.renewalApplicationInformation.state.toLocaleUpperCase();
    if (this.formPagination === 1) {
      this.trainingDuplicateIndexs = utils.duplicateValueIdx(
        this.renewalPayLoadData,
        "renewalTrainingInformation",
        true,
        "trainingName"
      );
      if (this.trainingDuplicateIndexs.length) {
        this.formPagination = 1;
        this.formName = "renewalTrainingInformation";
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }
    }

    if (this.formPagination === 2) {
      this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
        this.renewalPayLoadData,
        this.formName,
        true,
        "supervisorEmail"
      );
      if (this.supervisorEmailDuplicateIndexs.length) {
        this.formPagination = 2;
        this.formName = "renewalSupervisorInformation";
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 50);
        return;
      }

      const supervisorEmailValidate =
        this.renewalPayLoadData.renewalSupervisorInformation.filter(
          (supervisor: any) => {
            if (
              supervisor.supervisorEmail.toLowerCase() ===
              this.renewalPayLoadData.renewalApplicationInformation.email.toLowerCase()
            ) {
              return supervisor;
            }
          }
        );

      if (supervisorEmailValidate.length) {
        this.errorMessage =
          "Supervisor email id and applicant email id must be different *";
        return;
      }
    }

    if (this.formPagination == 4) {
      this.renewalPayLoadData.renewalSignatureSubmission.submissionDate =
        moment().format("MM-DD-YYYY");
      if (this.renewalPayLoadData.renewalSignatureSubmission.isSignature) {
        this.signatureInvalid = false;
      } else {
        this.checkValidForm = true;
        this.signatureInvalid = true;
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
        return;
      }
    }

    this.$validator.validateAll().then(async (validate) => {
      if (
        this.supervisorEmailDuplicateIndexs.length === 0 &&
        !this.notSavedTrainingIndex.length &&
        validate &&
        !this.notFilesUploadedKeyNames.length &&
        this.validSOA &&
        this.trainingDuplicateIndexs.length === 0
      ) {
        // Find Days count between Expiry Date and Current Date
        const startDate = moment(moment(new Date()).format("MM-DD-YYYY"));
        const endDate = moment(
          moment(
            this.renewalPayLoadData.renewalApplicationInformation
              .certificateExpirationDate
          ).format("MM-DD-YYYY")
        );
        const daysCount = Math.floor(endDate.diff(startDate, "days"));
        if (daysCount > this.renewalRemainder.days) {
          this.errorMessage = `You can't renewal application now for this certificate. Please try before ${this.renewalRemainder.days} days to expire.`;
          this.formPagination = 0;
          this.formName = "renewalApplicationInformation";
          return;
        }
        this.isLoading = true;
        this.checkValidForm = false;
        this.isValid = true;
        if (this.renewalPayLoadData.id == "") {
          await renewalFormApi
            .RenewalApplicationForm(
              this.renewalPayLoadData.fillStatus,
              this.renewalPayLoadData
            )
            .then((result: any) => {
              this.isLoading = false;
              this.renewalLocationId = result.headers.location;
              localStorage.setItem(
                "renewalApplicationId",
                this.renewalLocationId
              );
              this.renewalPayLoadData.id = localStorage.getItem(
                "renewalApplicationId"
              );
              localStorage.setItem(
                "renewalPayLoadData",
                JSON.stringify(this.renewalPayLoadData)
              );
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
        } else {
          const id = this.renewalPayLoadData.id;
          await renewalFormApi
            .updateRenewalForm(this.renewalPayLoadData, id)
            .then((data) => {
              this.isLoading = false;
              if (!this.isEdit) {
                this.nextPaginationUpdate();
              }
              const el = document.getElementById(this.mainId);
              if (el) {
                el.scrollIntoView({ behavior: "smooth" });
              }
            })
            .catch((error) => {
              this.errorMessage = utils.errorMessage(error);
              this.isLoading = false;
            });
          localStorage.setItem(
            "renewalPayLoadData",
            JSON.stringify(this.renewalPayLoadData)
          );
          if (this.isEdit) {
            this.formPagination = 5;
            this.formName = "renewalApplicationInformation";
          }
        }
        if (!this.formCompletedIndex.includes(this.formPagination)) {
          this.formCompletedIndex.push(this.formPagination);
        }
        this.currentForm = this.renewalPayLoadData[this.formName];
        localStorage.setItem(
          "renewalFormFilledIndex",
          this.formCompletedIndex.toString()
        );
      } else {
        setTimeout(() => {
          const el = document.getElementsByClassName("is-invalid")[0];
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }, 10);
      }
    });
  }

  public nextPaginationUpdate() {
    if (this.formPagination < this.formData.length - 1) {
      this.isNavLoad = true;
      const page = this.formPagination + 1;
      if (page == 0) {
        this.formName = "renewalApplicationInformation";
      } else if (page == 1) {
        this.formName = "renewalTrainingInformation";
        const renewalFormData =
          this.renewalPayLoadData.renewalTrainingInformation;
        if (renewalFormData.length) {
          this.showTrainingForm();
        }
      } else if (page == 2) {
        this.formName = "renewalSupervisorInformation";
      } else if (page == 3) {
        this.formName = "renewalHandbook";
      } else if (page == 4) {
        this.formName = "renewalSignatureSubmission";
      }
      this.formPagination = page;
    }
  }

  public previousButton() {
    this.notFilesUploadedKeyNames = [];
    this.supervisorEmailDuplicateIndexs = [];
    this.isEdit = false;
    if (this.formPagination !== 0) {
      const el = document.getElementById(this.mainId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "end" });
      }
      this.formPagination = this.formPagination - 1;
      if (this.formPagination == 0) {
        this.formName = "renewalApplicationInformation";
      } else if (this.formPagination == 1) {
        this.formName = "renewalTrainingInformation";
        const renewalFormData =
          this.renewalPayLoadData.renewalTrainingInformation;
        if (renewalFormData.length) {
          this.showTrainingForm();
        }
      } else if (this.formPagination == 2) {
        this.formName = "renewalSupervisorInformation";
      } else if (this.formPagination == 3) {
        this.formName = "renewalHandbook";
      } else if (this.formPagination == 4) {
        this.formName = "renewalSignatureSubmission";
      }
    }
  }
  public onSubmit() {
    this.$validator.validate().then((validate) => {
      if (validate) {
        this.renewalPayLoadData.renewalApplicationInformation.state =
          this.renewalPayLoadData.renewalApplicationInformation.state.toLocaleUpperCase();
        this.renewalPayLoadData.renewalSignatureSubmission.submissionDate =
          moment().format("MM-DD-YYYY");
        this.isLoading = true;
        let payloadStorage: any = localStorage.getItem("renewalPayLoadData");
        if (payloadStorage) {
          payloadStorage = JSON.parse(payloadStorage);
          this.renewalPayLoadData = { ...payloadStorage };
        }
        this.renewalPayLoadData.fillStatus = "COMPLETED";
        if (localStorage.getItem("renewalApplicationId")) {
          this.renewalPayLoadData.id = localStorage.getItem(
            "renewalApplicationId"
          );
        }
        renewalFormApi
          .updateRenewalForm(
            this.renewalPayLoadData,
            this.renewalPayLoadData.id
          )
          .then(() => {
            const someData = {
              title: "Certified Peer Recovery Specialist Application",
              SubTitle: "Renewal Application",
            };
            this.$router.push({
              path: "/form/submitted",
              query: { subTitle: someData.SubTitle },
            });
            this.isLoading = false;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
            localStorage.removeItem("renewalFormFilledIndex");
            localStorage.removeItem("renewalPayLoadData");
            localStorage.removeItem("renewalApplicationId");
          })
          .catch((error) => {
            this.errorMessage = utils.errorMessage(error);
            this.isLoading = false;
          });
      }
    });
  }

  public saveDraft() {
    this.$bvToast.show("example-toast");
    this.isValid = true;
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
    this.saveDataDraft = true;
    setTimeout(() => {
      this.saveDataDraft = false;
    }, 1000);
  }

  public closeToast() {
    this.$bvToast.hide("example-toast");
  }

  public showTrainingForm() {
    const renewalFormData = this.renewalPayLoadData.renewalTrainingInformation;
    if (renewalFormData.length) {
      this.addTrainingForm = [];
      renewalFormData.forEach(() => {
        this.addTrainingForm.push(this.formData[1].fields.formInputs);
      });
    }
  }

  public formEdit(slug: any) {
    this.isEdit = true;
    this.formName = slug;
    if (slug == "renewalApplicationInformation") {
      this.formPagination = 0;
    } else if (slug == "renewalTrainingInformation") {
      const renewalFormData =
        this.renewalPayLoadData.renewalTrainingInformation;
      if (renewalFormData.length) {
        this.showTrainingForm();
      }
      this.formPagination = 1;
    } else if (slug == "renewalSupervisorInformation") {
      this.formPagination = 2;
    } else if (slug == "renewalHandbook") {
      this.formPagination = 3;
    } else if (slug == "renewalSignatureSubmission") {
      this.renewalPayLoadData.renewalSignatureSubmission.isSignature = "";
      localStorage.setItem(
        "renewalPayLoadData",
        JSON.stringify(this.renewalPayLoadData)
      );
      this.formPagination = 4;
    }
    const el = document.getElementById(this.mainId);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }

  public pushTrainingForm() {
    this.addTrainingForm.push(this.formData[1].fields.formInputs);
    this.renewalPayLoadData.renewalTrainingInformation.push({
      trainingName: "",
      trainingDuration: "",
      trainingFormat: "",
      proofOfAttendance: [],
    });
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
  }

  public addTraining() {
    this.isFileValid = !this.isFileValid;
    const renewalFormData = this.renewalPayLoadData.renewalTrainingInformation;
    if (renewalFormData.length) {
      this.$validator.validateAll().then((validate) => {
        if (validate && !this.notFilesUploadedKeyNames.length) {
          if (this.addTrainingForm.length) {
            this.pushTrainingForm();
          }
        } else {
          setTimeout(() => {
            const el = document.getElementsByClassName("is-invalid")[0];
            if (el) {
              el.scrollIntoView({ behavior: "smooth", block: "end" });
            }
          }, 10);
        }
      });
    } else {
      this.pushTrainingForm();
    }
  }

  public removeTraining(index: number) {
    this.notFilesUploadedKeyNames = [];
    this.isTrainingReferesh = this.changeStateForTraining(
      this.isTrainingReferesh
    );
    this.renewalPayLoadData.renewalTrainingInformation.splice(index, 1);
    this.addTrainingForm.splice(index, 1);
    this.showTrainingForm();
    localStorage.setItem(
      "renewalPayLoadData",
      JSON.stringify(this.renewalPayLoadData)
    );
    setTimeout(() => {
      this.isTrainingReferesh = this.changeStateForTraining(
        this.isTrainingReferesh
      );
    }, 10);
  }

  public changeStateForTraining(value: string) {
    if (value === "false") {
      return "true";
    } else {
      return "false";
    }
  }

  public numberValidation(evt: any, length?: number) {
    utils.onlyNumberKey(evt, length);
  }

  public telCharacterValidate(evt: any, length?: number) {
    utils.telCharacterValidate(evt, length);
  }

  public onlyAlphabets(evt: any, fieldName = "") {
    utils.onlyAlphabets(evt, fieldName);
  }

  public fieldValidation(
    value: any,
    eventName: string,
    fieldType: string,
    maxLength = 0,
    fieldName = ""
  ) {
    if (fieldType === "number") {
      this.eventBind[eventName](value, maxLength);
    } else if (fieldName === "state") {
      this.eventBind[eventName](value, fieldName);
    } else {
      this.eventBind[eventName](value);
    }
  }

  public emptyEvent() {
    return;
  }

  public allFilesUploaded(event: any) {
    if (this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.splice(event, 1);
    }
  }

  public notFileUploaded(event: string) {
    if (!this.notFilesUploadedKeyNames.includes(event)) {
      this.notFilesUploadedKeyNames.push(event);
    }
  }

  public resetFileKey(event: boolean) {
    if (event) {
      this.notFilesUploadedKeyNames = [];
    }
  }

  public async navigateTab(slug: string, index: number) {
    if (this.formCompletedIndex.includes(index)) {
      this.isEdit = false;
      if (index > this.formPagination) {
        this.checkSOAValid = !this.checkSOAValid;
        this.isFileValid = !this.isFileValid;
        this.checkValidForm = true;

        if (index > 1) {
          this.trainingDuplicateIndexs = utils.duplicateValueIdx(
            this.renewalPayLoadData,
            "renewalTrainingInformation",
            false,
            "trainingName"
          );
          if (this.trainingDuplicateIndexs.length) {
            this.formPagination = 1;
            this.formName = "renewalTrainingInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }
        }

        if (index > 2) {
          this.supervisorEmailDuplicateIndexs = utils.duplicateValueIdx(
            this.renewalPayLoadData,
            "renewalSupervisorInformation",
            true,
            "supervisorEmail"
          );
          if (this.supervisorEmailDuplicateIndexs.length) {
            this.formPagination = 2;
            this.formName = "renewalSupervisorInformation";
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 50);
            return;
          }

          const supervisorEmailValidate =
            this.renewalPayLoadData.renewalSupervisorInformation.filter(
              (supervisor: any) => {
                if (
                  supervisor.supervisorEmail.toLowerCase() ===
                  this.renewalPayLoadData.renewalApplicationInformation.email.toLowerCase()
                ) {
                  return supervisor;
                }
              }
            );
          if (supervisorEmailValidate.length) {
            this.formPagination = 2;
            this.formName = "renewalSupervisorInformation";
            this.errorMessage =
              "Supervisor email id and applicant email id must be different *";
            return;
          }
        }

        //signature validation
        if (this.formPagination == 4) {
          if (this.renewalPayLoadData.renewalSignatureSubmission.isSignature) {
            this.signatureInvalid = false;
          } else {
            this.signatureInvalid = true;
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
            return;
          }
        }

        this.$validator.validateAll().then(async (validate) => {
          if (
            this.supervisorEmailDuplicateIndexs.length === 0 &&
            !this.notSavedTrainingIndex.length &&
            validate &&
            !this.notFilesUploadedKeyNames.length &&
            this.validSOA &&
            this.trainingDuplicateIndexs.length === 0
          ) {
            if (index > 0) {
              // Find Days count between Expiry Date and Current Date
              const startDate = moment(moment(new Date()).format("MM-DD-YYYY"));
              const endDate = moment(
                moment(
                  this.renewalPayLoadData.renewalApplicationInformation
                    .certificateExpirationDate
                ).format("MM-DD-YYYY")
              );
              const daysCount = Math.floor(endDate.diff(startDate, "days"));
              if (daysCount > this.renewalRemainder.days) {
                this.errorMessage =
                  "You can't renewal application now for this certificate. Please try before 90 days to expire.";
                this.formPagination = 0;
                this.formName = "renewalApplicationInformation";
                return;
              }
            }
            this.checkValidForm = false;
            this.isValid = true;
            const el = document.getElementById(this.mainId);
            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
            localStorage.setItem(
              "renewalPayLoadData",
              JSON.stringify(this.renewalPayLoadData)
            );
            if (index === 5) {
              this.formPagination = index;
              this.formName = "renewalApplicationInformation";
            } else {
              this.formName = slug;
              this.formPagination = index;
              if (this.formName === "renewalTrainingInformation") {
                const renewalFormData =
                  this.renewalPayLoadData.renewalTrainingInformation;
                if (renewalFormData.length) {
                  this.showTrainingForm();
                }
              }
            }
          } else {
            setTimeout(() => {
              const el = document.getElementsByClassName("is-invalid")[0];
              if (el) {
                el.scrollIntoView({ behavior: "smooth", block: "end" });
              }
            }, 10);
          }
        });
      } else {
        this.notFilesUploadedKeyNames = [];
        this.supervisorEmailDuplicateIndexs = [];
        if (index === 5) {
          this.formPagination = index;
          this.formName = "renewalApplicationInformation";
        } else {
          this.formName = slug;
          this.formPagination = index;
          if (this.formName === "renewalTrainingInformation") {
            const renewalFormData =
              this.renewalPayLoadData.renewalTrainingInformation;
            if (renewalFormData.length) {
              this.showTrainingForm();
            }
          }
        }
        const el = document.getElementById(this.mainId);
        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }

  public addAnother() {
    this.renewalPayLoadData.renewalSupervisorInformation.push({
      supervisorFirstName: "",
      supervisorLastName: "",
      supervisorAgencyOrganization: "",
      supervisorPhone: "",
      supervisorEmail: "",
    });
    this.supervisorFormFields.push(this.tempSupervisorFormFields);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public removeAddedSupervisor(indexForm: number) {
    this.renewalPayLoadData.renewalSupervisorInformation.splice(indexForm, 1);
    this.supervisorFormFields.splice(indexForm, 1);
    this.supervisorUIUpdate = utils.UIUpdateUsingKey(this.supervisorUIUpdate);
  }

  public classNameChooser(inputClass: string) {
    return utils.classNameChooser(inputClass);
  }

  public closdedErrorPopup(event: any) {
    if (event) {
      this.errorMessage = "";
    }
  }
  public goBack() {
    if (this.$route.query.isDraft) {
      localStorage.removeItem("renewalPayLoadData");
    }
    this.$router.push({
      path: `/application`,
      query: {
        form: "renewal",
      },
    });
    const el = document.getElementById("header");
    if (el) {
      el.classList.add("active");
      el.classList.remove("remove-container");
    }
  }
}
