import { Component, Prop, Vue } from "vue-property-decorator";
@Component({})
export default class EligibilityPopupComponent extends Vue {
  @Prop()
  public formInput!: string;

  public created() {
    this.$nextTick(() => {
      if (this.formInput) {
        this.$bvModal.show("error-modal-center");
      }
    });
  }

  public closePopup() {
    this.$emit("closedEligibilityPopup", false);
    this.$router.push({
      path: `/application`,
      query: {
        form: "part-one",
      },
    });
  }

  public hide(event: any) {
    if (event) {
      this.$emit("closedEligibilityPopup", true);
    }
  }
  public onSubmit() {
    this.$emit("closedEligibilityPopup", true);
  }
}
